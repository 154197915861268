import React, { useEffect, useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { addToCart, removeFromCart } from '../redux/actions/cartActions';

function BagItem({
  _id,
  slug,
  image,
  brand,
  name,
  price,
  _qty,
  countInStock
}) {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(_qty);

  const decrementQty = () => {
    if (qty > 1)
      setQty(qty - 1);
  };

  const incrementQty = () => {
    if (qty < countInStock)
      setQty(qty + 1);
  };

  const handleRemoveFromCart = (id) => {
    dispatch(removeFromCart(id));
  };

  useEffect(() => {
    dispatch(addToCart(slug, Number(qty)));
  }, [dispatch, qty, slug]);

  return (
    <div key={_id} className="flex items-center justify-between mb-8 gap-x-4">

      <div className="flex items-center">
        <Link to={`/product/${slug}`}>
          <img
            src={image}
            alt=""
            className="w-16 sm:w-32"
          />
        </Link>
        <div className="ml-4 md:ml-12 text-sm">
          <Link to={`/product/${slug}`}>
            <p>{brand + ' ' + name}</p>
          </Link>
          <p className="font-bold mt-1">${(price * qty).toFixed(2)}</p>
        </div>
      </div>

      <div className="flex">

        <div className="flex items-center text-gray-500">
          <button
            className="px-3 text-gray-500 text-[22px]"
            onClick={incrementQty}
          >+</button>
          <p className="text-lg">/</p>
          <button
            className="px-3 text-gray-500 text-[20px]"
            onClick={decrementQty}
          >–</button>
        </div>
        
        <input
          type="number"
          min="1"
          max={countInStock <= 5 ? countInStock : 5}
          placeholder={1} className="border-0 rounded-md ring-2 ring-gray-300 focus:ring-2 focus:ring-gray-300 text-gray-600 px-2 w-9 mt-1 text-center leading-3 ml-1"
          value={qty}
          onChange={(e) => setQty(e.target.value)}
        />

        <button
          className="p-2"
          onClick={() => handleRemoveFromCart(_id)}
        >
          <IoTrashOutline className="h-5 w-5 text-zinc-400" />
        </button>
      </div>
    </div>
  );
}

export default BagItem;
