import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  loading: false
};

export const productSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    productListRequest(state) {
      state.products = [];
      state.loading = true;
    },
    productListSuccess(state, action) {
      state.loading = false;
      state.products = action.payload.products;
      state.page = action.payload.page;
      state.pages = action.payload.pages;
    },
    productListFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default productSlice.reducer;
