import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Meta from '../components/Meta';

import { HiChevronLeft, HiOutlineX } from 'react-icons/hi';
import { scrollToTop } from '../lib/scrollToTop';
import { getOrderDetails, deliverOrder } from '../redux/actions/orderActions';
import { ORDER_DELIVER_RESET } from '../redux/constants/orderConstants';
import LoadingDots from '../components/LoadingDots';

function AdminEditOrder() {
  const [hideUpdated, setHideUpdated] = useState(true);
  const [hideUpdatedError, setHideUpdatedError] = useState(true);

  const params = useParams();
  const { id: orderId } = params;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo: userInfoLogin } = userLogin;

  const orderDetails = useSelector(state => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderDeliver = useSelector(state => state.orderDeliver);
  const { success: successDeliver, loading: loadingDeliver } = orderDeliver;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userInfoLogin) {
      navigate('/404');
    }
    if (userInfoLogin) {
      if (!userInfoLogin.isAdmin)
        navigate('/404');
    }
  }, [userInfoLogin, navigate]);

  useEffect(() => {
    if (!order || order._id !== Number(orderId) || successDeliver) {
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    }

  }, [dispatch, orderId, order, successDeliver]);

  const handleDeliver = () => {
    dispatch(deliverOrder(order));
  };

  return (
    <>
      <Meta title="Edit Order | Echidna Admin" />

      <div
        className="inline-flex items-center text-sm cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <HiChevronLeft className="mr-1" />
        Back
      </div>

      {loading ?
        <div className="flex justify-center pt-14">
          <LoadingDots />
        </div>
        : error ? <h2 className="text-xl text-center mt-40">{error}</h2>
          : <>

            <h2 className="text-xl mb-4 font-bold border-b-2 pb-2 mt-8">Echidna Order</h2>

            <div className={`flex items-center mb-8 max-w-lg justify-center border shadow-sm rounded-md mx-auto relative ${hideUpdated ? 'hidden' : ''}`}>
              <p
                className={`text-xs text-center p-2 rounded font-bold text-gray-700`}
                onClick={() => setHideUpdated(true)}
              >Your changes have been saved
              </p>
              <HiOutlineX
                className="cursor-pointer absolute right-2 text-gray-400"
                onClick={() => setHideUpdated(true)}
              />
            </div>


            <div className="grid md:grid-cols-3 md:gap-6">

              <div className="md:col-span-1 mb-8">
                <h3 className="">Echidna Order Details</h3>
                <p className="mt-1 text-sm text-gray-600">Edit Echidna order details.</p>
              </div>

              <div className="col-span-2">

                <p className="font-bold mb-2">Order #{order._id}</p>

                <div className="grid lg:grid-cols-2 gap-x-4 gap-y-4 text-sm">
                  <div>
                    <p>Shipping Address</p>

                    <p>
                      {order.user.name}
                    </p>
                    <p>{order.shippingAddress.address}</p>
                    <p>{order.shippingAddress.city} {order.shippingAddress.state},  {order.shippingAddress.postalCode}</p>
                    <p>{order.shippingAddress.country}</p>

                    <h2 className="mt-4">Email</h2>
                    <p>{order.user.email}</p>
                  </div>

                  <div className="">
                    <h2>Payment Method</h2>
                    <p>{order.paymentMethod}</p>


                    {order.isPaid && (<>
                      <p className="mt-4">Payment Status</p>
                      <p>Paid at {' '}
                        <Moment format="H:mm on L">
                          {order.paidAt}
                        </Moment>
                      </p>
                    </>
                    )}

                    {order.isPaid && (
                      order.isDelivered ? (<>
                        <p className="mt-4">Delivery Status</p>
                        <p>Delivered at{' '}<Moment format="H:mm on L">
                          {order.deliveredAt}
                        </Moment>
                        </p>
                      </>
                      ) : <>
                        <p className="mt-4">Delivery Status</p>
                        <p className="inline">Not Delivered</p>
                        <button
                          onClick={handleDeliver}
                          className="px-3 py-2 border rounded-full ml-4 text-xs ">Mark as delivered?</button>
                      </>)}
                  </div>

                </div>


                <div className="mt-14 col-span-full">
                  <h2 className="pb-3 border-b text-sm font-bold">Order Items</h2>
                  {order.orderItems.length === 0
                    ? 'Your bag is empty'
                    : order.orderItems.map((item, index) => (
                      <div key={index} className="flex justify-between items-center text-sm mt-4">
                        <div className="flex items-center">
                          <Link to={`/product/${item.slug}`}>
                            <img
                              src={item.image}
                              alt=""
                              className="w-20 sm:w-32 mr-10"
                            />
                          </Link>

                          <Link to={`/product/${item.slug}`}>{item.brand + ' ' + item.name}</Link>
                        </div>

                        <div className="ml-3 text-right">
                          <p>Quantity: {item.qty}</p>
                          <p>${item.price}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>

                <div className="text-sm text-center space-y-1 mt-14">
                  <div className="rounded-lg border border-gray-300 py-4 bg-gray-100">
                    <h3 className="mb-4 text-lg font-bold">Order Summary</h3>
                    <p>Subtotal: ${order.itemsPrice}</p>
                    <p>Shipping: {order.itemsPrice > 100 ? 'Free' : `$${10}`}</p>
                    <p className="">Tax: ${order.taxPrice}</p>

                    <div className="h-6" />

                    <p className="font-bold">Total: ${order.totalPrice}</p>

                  </div>
                </div>

              </div>
            </div>

          </>}
    </>
  );
}

export default AdminEditOrder;
