import React, { useState, useEffect } from 'react';
import { Link, useNavigate, matchPath, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderDetails } from '../redux/actions/orderActions';
import { ORDER_PAY_RESET } from '../redux/constants/orderConstants';

import Meta from '../components/Meta';
import Moment from 'react-moment';
import { HiChevronLeft } from 'react-icons/hi';
import { productDetailsReset } from '../redux/actions/productActions';
import LoadingDots from '../components/LoadingDots';

function OrderDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { id: orderId } = params;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const orderDetails = useSelector(state => state.orderDetails);
  const { order, error, loading } = orderDetails;

  if (!loading && !error) {
    order.itemsPrice = order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2);
  }

  const productDetails = useSelector(state => state.productDetails);
  const { product } = productDetails;

  useEffect(() => {
    if (product.name) {
      dispatch(productDetailsReset());
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (!userInfo) {
      navigate('/');
    }

    if (!order || order._id !== Number(orderId)) {
      // Reset order if paid
      dispatch({ type: ORDER_PAY_RESET });
      dispatch(getOrderDetails(orderId));
    }
  }, [orderId, order, dispatch, userInfo, navigate]);

  return loading ?
    <>
      <div
        className="inline-flex items-center text-sm cursor-pointer mb-6"
        onClick={() => navigate(-1)}
      >
        <HiChevronLeft className="mr-1" />
        Back
      </div>

      <div className="flex justify-center pt-14">
        <LoadingDots />
      </div>
    </>
    : error ? <h2 className="text-xl text-center mt-40">{error}</h2>
      : (
        <>
          <Meta
            title={`${!order.isDelivered
              ? `Order #${order._id} | Echidna` : `Order #${order._id} Complete | Echidna`}
              `}
          />

          <div
            className="inline-flex items-center text-sm cursor-pointer mb-6"
            onClick={() => navigate(-1)}
          >
            <HiChevronLeft className="mr-1" />
            Back
          </div>

          {!order.isDelivered
            ? <h2 className="text-xl font-bold mb-6 border-b-2 pb-2">
              Order #{order._id}
            </h2>
            : <h2 className="text-xl font-bold mb-6 border-b-2 pb-2">Order #{order._id} Complete</h2>}

          <div className="grid lg:grid-cols-4 gap-x-8 gap-y-8">
            <div className="lg:col-span-3">
              <div className="grid lg:grid-cols-2 gap-x-4 gap-y-4 text-sm">
                <div>
                  <p>Shipping Address</p>

                  <p>
                    {order.user.name}
                  </p>
                  <p>{order.shippingAddress.address}</p>
                  <p>{order.shippingAddress.city} {order.shippingAddress.state},  {order.shippingAddress.postalCode}</p>
                  <p>{order.shippingAddress.country}</p>

                  <h2 className="mt-4">Email</h2>
                  <p>{order.user.email}</p>
                </div>

                <div>
                  <h2>Payment Method</h2>
                  <p>{order.paymentMethod}</p>


                  {order.isPaid && (<>
                    <p className="mt-4">Payment Status</p>
                    <p>Paid at {' '}
                      <Moment format="H:mm on L">
                        {order.paidAt}
                      </Moment>
                    </p>
                  </>
                  )}

                  {order.isPaid && (
                    order.isDelivered ? (<>
                      <p className="mt-4">Delivery Status</p>
                      <p>Delivered {' '}<Moment format="H:mm on L">
                        {order.deliveredAt}
                      </Moment>
                      </p>
                    </>
                    ) : <>
                      <p className="mt-4">Delivery Status</p>
                      <p>Not Delivered</p>
                    </>)}
                </div>

              </div>

              <div className="mt-14">
                <h2 className="pb-3 border-b text-sm font-bold">Order Items</h2>
                {order.orderItems.length === 0
                  ? 'Your bag is empty'
                  : order.orderItems.map((item, index) => (
                    <div key={index} className="flex justify-between items-center text-sm mt-4">
                      <div className="flex items-center">
                        <Link to={`/product/${item.slug}`}>
                          <img
                            src={item.image}
                            alt=""
                            className="w-20 sm:w-32 mr-10"
                          />
                        </Link>

                        <Link to={`/product/${item.slug}`}>{item.brand + ' ' + item.name}</Link>
                      </div>

                      <div className="ml-3 text-right">
                        <p>Quantity: {item.qty}</p>
                        <p>${item.price}</p>
                      </div>
                    </div>
                  ))
                }
              </div>

            </div>

            <div className="text-sm text-center space-y-1 mt-5 sm:mt-0">
              <div className="rounded-lg border border-gray-300 py-4 bg-gray-100">
                <h3 className="mb-4 text-lg font-bold">Order Summary</h3>
                <p>Subtotal: ${order.itemsPrice}</p>
                <p>Shipping: {order.itemsPrice > 100 ? 'Free' : `$${10}`}</p>
                <p className="">Tax: ${order.taxPrice}</p>

                <div className="h-6" />

                <p className="font-bold">Total: ${order.totalPrice}</p>
              </div>

            </div>
          </div>

        </>
      );
}

export default OrderDetails;
