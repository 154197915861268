import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserDetails, updateUser } from '../redux/actions/userActions';
import { Switch } from '@headlessui/react';
import { USER_UPDATE_RESET } from '../redux/constants/userConstants';

import Meta from '../components/Meta';

import { HiChevronLeft, HiOutlineX } from 'react-icons/hi';
import LoadingDots from '../components/LoadingDots';
import { scrollToTop } from '../lib/scrollToTop';


function AdminEditUser() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [hideUpdated, setHideUpdated] = useState(true);
  const [hideUpdatedError, setHideUpdatedError] = useState(true);

  const params = useParams();
  const { id: userId } = params;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo: userInfoLogin } = userLogin;

  const userDetails = useSelector(state => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector(state => state.userUpdate);
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = userUpdate;


  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userInfoLogin) {
      navigate('/404');
    }
    if (userInfoLogin) {
      if (!userInfoLogin.isAdmin)
        navigate('/404');
    }
  }, [userInfoLogin, navigate]);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      // navigate('/portal/admin/user-list');
    }
    else {
      if (!user.name || user._id !== Number(userId)) {
        dispatch(getUserDetails(userId));
        setIsAdmin(user.isAdmin);
      } else {
        setName(user.name);
        setEmail(user.email);
        setIsAdmin(user.isAdmin);
      }
    }

  }, [user, userId, successUpdate, dispatch, navigate]);

  const submit = (e) => {
    e.preventDefault();

    dispatch(updateUser({ _id: user._id, name, email, isAdmin }));

    if (!hideUpdated) {
      setHideUpdated(true);
      setTimeout(() => {
        setHideUpdated(false);
      }, 1000);
    } else {
      setHideUpdated(false);
    }

    scrollToTop();
  };

  return (
    <>
      <Meta title="Edit User | Echidna Admin" />

      <div
        className="inline-flex items-center text-sm cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <HiChevronLeft className="mr-1" />
        Back
      </div>

      {loading ?
        <div className="flex justify-center pt-14">
          <LoadingDots />
        </div>
        : error ? <h2 className="text-xl text-center mt-40">{error}</h2>
          : <>

            <h2 className="text-xl mb-4 font-bold border-b-2 pb-2 mt-8">Echidna User</h2>

            <div className={`flex items-center mb-8 max-w-lg justify-center border shadow-sm rounded-md mx-auto relative ${hideUpdated ? 'hidden' : ''}`}>
              <p
                className={`text-xs text-center p-2 rounded font-bold text-gray-700`}
                onClick={() => setHideUpdated(true)}
              >
                Your changes have been saved
              </p>
              <HiOutlineX
                className="cursor-pointer absolute right-2 text-gray-400"
                onClick={() => setHideUpdated(true)}
              />
            </div>

            {errorUpdate &&
              <div className={`flex items-center mb-8 max-w-lg justify-center border shadow-sm rounded-md mx-auto relative ${hideUpdatedError ? 'hidden' : ''}`}>
                <p
                  className={`text-xs text-center p-2 rounded font-bold text-gray-700`}
                  onClick={() => setHideUpdatedError(true)}
                >
                  {errorUpdate}
                </p>
                <HiOutlineX
                  className="cursor-pointer absolute right-2 text-gray-400"
                  onClick={() => setHideUpdatedError(true)}
                />
              </div>}

            <div className="md:grid md:grid-cols-3 md:gap-6 mt-10 sm:mt-0">

              <div className="md:col-span-1">
                <h3 className="">Echidna User Details</h3>
                <p className="mt-1 text-sm text-gray-600">Edit Echidna user account details.</p>
              </div>

              <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={submit} className="border rounded-md">

                  <div className="grid grid-cols-6 gap-6 px-4 py-5 sm:p-6">

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={name ?? ''}
                        onChange={(e) => setName(e.target.value)}
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        onClick={() => setHideUpdated(true)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <input
                        type="text"
                        id="email-address"
                        autoComplete="email"
                        value={email ?? ''}
                        onChange={(e) => setEmail(e.target.value)}
                        onClick={() => setHideUpdated(true)}
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="flex items-center justify-between col-span-full">
                      <div className="flex items-center">
                        {/* <input
                                id="admin"
                                name="admin"
                                type="checkbox"
                                className="h-4 w-4 text-indigo-400 focus:ring-0 border-gray-300 rounded"
                                value={isAdmin}
                                onChange={(e) => setIsAdmin(e.target.checked)}
                                onClick={() => setHideUpdated(true)}
                                checked={isAdmin ?? ''}
                              /> */}


                        <Switch
                          checked={isAdmin}
                          onChange={setIsAdmin}
                          className={`${isAdmin ? 'bg-slate-600' : 'bg-gray-200'
                            } inline-flex items-center h-6 rounded-full w-11 mr-3 ring-2 ring-gray-300`}
                        >
                          <span
                            className={`${isAdmin ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-4 h-4 transform duration-100 bg-white rounded-full`}
                          />
                        </Switch>

                        <label htmlFor="admin" className="block text-sm text-gray-900 select-none">
                          Admin privileges
                        </label>

                      </div>

                    </div>
                    <p className="col-span-full text-xs -mt-5 text-gray-600 ml-14"> (Warning: this will allow user with access to sensitive information)</p>

                  </div>

                  <div className="px-4 py-3 bg-gray-100 rounded-b-md text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md text-gray-700 border-gray-500
           focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* <div className="py-8">
                <div className="border-t border-gray-200" />
              </div>

              <div className="md:grid md:grid-cols-3 md:gap-6">

                <div className="md:col-span-1">
                  <div className="sm:mt-0">
                    <h3 className="">Shipping Information</h3>
                    <p className="mt-1 text-sm text-gray-600">Set your shipping address.</p>
                  </div>
                </div>

                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>

                    <div className="border rounded-md">

                      <div className="px-4 py-5 sm:p-6"> */}

            {/* <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="name_shipping_address" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name_shipping_address"
                        placeholder={shippingAddress ? shippingAddress.name : ''}
                        // value=""
                        // onChange={(e) => setName(e.target.value)}
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                        Street address
                      </label>
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        autoComplete="street-address"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={shippingAddress ? shippingAddress.address : ''}
                      // value={shippingAddress.}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={shippingAddress ? shippingAddress.city : ''}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        State
                      </label>
                      <input
                        type="text"
                        name="region"
                        id="region"
                        autoComplete="address-level1"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={shippingAddress ? shippingAddress.state : ''}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                        ZIP / Postal code
                      </label>
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={shippingAddress ? shippingAddress.postalCode : ''}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 text-sm"
                        placeholder={shippingAddress ? shippingAddress.country : ''}
                      >
                        <option>United States</option>
                        <option>Canada</option>
                        <option>UK</option>
                      </select>
                    </div>

    </div> */}
            {/* </div>

                      <div className="px-4 py-3 bg-gray-100 rounded-b-md text-right sm:px-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md text-gray-700 border-gray-500
                     focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}

            {/* <div className="py-8">
        <div className="border-t border-gray-200" />
      </div> */}

            {/* <div className="md:grid md:grid-cols-3 md:gap-6">

        <div className="md:col-span-1">
          <div className="">
            <h3 className="">Notifications</h3>
            <p className="mt-1 text-sm text-gray-600">Change your notification preferences.</p>
          </div>
        </div>

        <div className="mt-5 md:mt-0 md:col-span-2">
          <form>
            <div className="border sm:rounded-md">
              <div className="px-4 py-5 space-y-6 sm:p-6">

                <fieldset>
                  <legend className="text-base font-medium text-gray-900">By Email</legend>

                  <div className="flex items-start mt-4">
                    <div className="flex items-center h-5">
                      <input
                        id="order-info"
                        type="checkbox"
                        className="focus:ring-0 h-4 w-4 text-cyan-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="order-info" className="font-medium text-gray-700">
                        Order Status
                      </label>
                      <p className="text-gray-500">Get notified with order updates.</p>
                    </div>
                  </div>

                  <div className="flex items-start mt-4">
                    <div className="flex items-center h-5">
                      <input
                        id="newsletters"
                        type="checkbox"
                        className="focus:ring-0 h-4 w-4 text-cyan-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="newsletters" className="font-medium text-gray-700">
                        Newsletters
                      </label>
                      <p className="text-gray-500">Get notified with latest products and promotions.</p>
                    </div>
                  </div>

                </fieldset>

              </div>

              <div className="px-4 py-3 bg-gray-100 text-right sm:px-6 rounded-b-md">
                <button
                  type="submit"
                  className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md 
                    text-gray-700 border-gray-500
                     focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
    </div> */}
          </>}
    </>
  );
}

export default AdminEditUser;
