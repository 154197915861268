import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { classNames } from '../lib/classNames';

function SelectBox({ selectBoxValue, setSelectBoxValue, selectBoxValues, selectButtonText }) {
  return (
    <Listbox value={selectBoxValue} onChange={setSelectBoxValue}>
      {({ open }) => (
        <div className="mt-1 relative">
          <Listbox.Button className="text-sm border border-gray-300 focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500 w-full text-left px-4 py-2 rounded-md">{selectBoxValue ?
            <span className="flex items-center justify-between">
              {selectBoxValue}
              <HiOutlineChevronDown className="text-gray-500" />
            </span>
            :
            <span className="flex items-center justify-between">
              Select {selectButtonText}
              <HiOutlineChevronDown className="text-gray-500" />
            </span>
          }</Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >

            <Listbox.Options className="absolute z-10 mt-1.5 w-full bg-white shadow-md rounded-md py-1 ring-1 ring-gray-300 overflow-auto focus:outline-none text-sm -ml-[0px]">

              {selectBoxValues.map((v) => (
                <Listbox.Option
                  key={v.id}
                  className={({ active }) =>
                    classNames(
                      active && ' bg-gray-100 rounded',
                      'select-none py-1.5 cursor-pointer mx-[3px]'
                    )
                  }
                  value={v.value}
                >
                  {({ selectBoxValue, active }) => (
                    <div className="flex items-center">
                      <span
                        className={classNames(selectBoxValue ? 'font-bold' : '', 'truncate flex items-center justify-between')}
                      >
                        {v.value}
                      </span>
                    </div>

                  )}
                </Listbox.Option>
              ))}

            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export default SelectBox;