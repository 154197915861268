import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { register } from '../redux/actions/userActions';
import Meta from '../components/Meta';

function RegisterPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const userRegister = useSelector(state => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo: userInfoLogin } = userLogin;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfoLogin || userInfo) {
      navigate('/');
    }
  }, [userInfoLogin, userInfo, redirect, navigate]);

  const submit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    } else {
      dispatch(register(name, email, password));
    }
  };

  return (
    <>
      <Meta title="Register | Echidna" />

      <div className="min-h-full flex items-center justify-center py-12">
        <div className="max-w-md w-full space-y-8 border rounded-lg">
          <div className="bg-gray-100 rounded-t-lg border-b pb-3">
            <h2 className="pt-4 text-center text-2xl text-gray-900">Register</h2>
            <p className="mt-1 text-center text-xs text-gray-600 px-2">
              Already have an account?{' '}
              <Link to={redirect ? `/login?redirect=${redirect}` : '/login'} className="font-medium text-indigo-600 hover:text-indigo-500">
                Click here to login.
              </Link>
            </p>
          </div>

          <form className="space-y-6 px-3 md:px-6 pb-6" onSubmit={submit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              <input
                name="name"
                type="text"
                autoComplete="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 text-sm"
                placeholder="Name"
              />

              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 text-sm"
                placeholder="Email address"
              />

              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                name="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onClick={() => setMessage('')}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 text-sm"
                placeholder="Password"
              />

              <label className="sr-only">
                Confirm Password
              </label>
              <input
                name="confirmPassword"
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onClick={() => setMessage('')}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 text-sm"
                placeholder="Confirm password"
              />

            </div>

            <div className="flex items-center">
              <input
                id="newsletters"
                name="newsletters"
                type="checkbox"
                className="h-4 w-4 text-cyan-600 focus:ring-0 border-gray-300 rounded"
              />
              <label htmlFor="newsletters" className="ml-2 block text-xs text-gray-900 select-none">
                Sign up for newsletters
              </label>
            </div>

            {!error && !message && <div className="h-4" />}
            <p className="text-xs">{error}</p>
            <p className="text-xs">{message}</p>

            <button
              type="submit"
              disabled={loading}
              className={`w-full flex justify-center py-2.5 px-4 bg-black text-white text-sm rounded-md font-bold 
                transition duration-200 ease-in-out
                disabled:bg-gray-400
                disabled:cursor-not-allowed disabled:opacity-40 select-none`}
            >
              {loading ?
                <div className="flex justify-center">
                  <p className="">Signing up...</p>
                </div>
                : 'Sign up'}
            </button>
          </form>
        </div>
      </div>

    </>
  );
}

export default RegisterPage;
