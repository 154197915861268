import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoBagOutline } from "react-icons/io5";
import { BsPerson } from "react-icons/bs";

import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/actions/userActions';
import { listProducts } from '../redux/actions/productActions';
import NavSlider from './NavSlider';
import SearchBox from './SearchBox';
import { deleteShippingAddress, resetPaymentMethod } from '../redux/actions/cartActions';
import UserNavDropdown from './UserNavDropdown';

// import { ReactComponent as MyMenu } from '../icons/menu.svg';

function Header() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  if (typeof window === 'object') {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
  }

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const bag = useSelector(state => state.cart);
  const { cartItems } = bag;

  const handleLogout = () => {
    dispatch(logout());
    dispatch(listProducts());
    navigate('/');
    dispatch(deleteShippingAddress());
    dispatch(resetPaymentMethod());
  };

  const closeSlider = () => {
    setOpen(false);
  };

  return (
    <header
      className={`fixed w-full top-0 z-[100] py-3
    ${scrollY > 30
          ? 'opacity-[98%] md:opacity-[97%] bg-stone-200'
          : 'bg-stone-200'} 
        transition duration-75`
      }
    >
      <div className="max-w-screen-xl mx-auto">
        <div className="mx-4 sm:mx-6">
          <div className="flex items-center justify-between">

            <div className="flex items-center gap-x-2 sm:gap-x-8">
              <button className=" sm:hidden"
                onClick={() => setOpen(!open)}
              >
                <HiOutlineMenuAlt4 className="h-7 w-7 text-black" />
              </button>

              <Link to="/" className={`text-[20px] sm:text-[24px] font-bold py-[3px] sm:py-0 ${scrollY > 30
                ? ''
                : 'scale-[1.075]'} transition duration-150 ease-in-out ml-2 sm:ml-0`}>Echidna</Link>

              <Link to="/" className="text-sm hover:opacity-80 hidden sm:block">Products</Link>
            </div>

            {/* <MyMenu className="text-black h-6 w-6"/> */}

            <div className="flex items-center sm:space-x-1">
              <SearchBox />

              {userInfo ? (
                <UserNavDropdown
                  userInfo={userInfo}
                  handleLogout={handleLogout}
                />
              ) :
                <Link to="/login" className="flex items-center active:ring-2 active:rounded focus:ring-cyan-500">
                  <BsPerson className="mx-2 h-7 w-7" />
                </Link>}

              <Link to="/bag" className="flex items-center relative mr-0 sm:mr-0 active:ring-2 active:rounded active:ring-cyan-500">

                <IoBagOutline className="mr-1 h-6 w-6 text-slate-800" />

                <div
                  className="text-[10px] text-center h-5 w-5 pt-[2px] rounded-full font-bold bg-slate-800 text-white absolute -top-1 -right-1"
                >
                  {cartItems.reduce((acc, item) => acc + parseInt(item.qty), 0)}
                </div>

              </Link>

              {/* <Popover>

                <Popover.Button >
                  <HiMenu className="h-6 w-6" />
                </Popover.Button>
              </Popover>
 */}

            </div>

          </div>

          <NavSlider
            open={open}
            setOpen={setOpen}
            handleCloseSlider={closeSlider}
          />

          {/* <MobileNav /> */}

        </div>
      </div>
    </header>
  );
}

export default Header;
