import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { listProductDetails, updateProduct } from '../redux/actions/productActions';

import { PRODUCT_UPDATE_RESET } from '../redux/constants/productConstants';

import Meta from '../components/Meta';

import { HiChevronLeft, HiOutlineX } from 'react-icons/hi';
import axios from 'axios';
import { scrollToTop } from '../lib/scrollToTop';
import LoadingDots from '../components/LoadingDots';

function AdminEditProduct() {
  const [name, setName] = useState('');
  const [name_long, set_name_long] = useState('');
  const [name_long_2, set_name_long_2] = useState('');
  const [slug, setSlug] = useState('');
  const [brand, setBrand] = useState('');
  const [price, setPrice] = useState(0);
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [uploading, setUploading] = useState(false);

  const [hideUpdated, setHideUpdated] = useState(true);
  const [hideUpdatedError, setHideUpdatedError] = useState(true);

  const filePickerRef = useRef(null);

  const params = useParams();
  const { id: productId } = params;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo: userInfoLogin } = userLogin;

  const productDetails = useSelector(state => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector(state => state.productUpdate);
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = productUpdate;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userInfoLogin) {
      navigate('/404');
    }
    if (userInfoLogin) {
      if (!userInfoLogin.isAdmin)
        navigate('/404');
    }
  }, [userInfoLogin, navigate]);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      // navigate('/portal/admin/product-list');
    } else {
      if (!product.name || product._id !== Number(productId)) {
        dispatch(listProductDetails(productId));
      } else {
        setName(product.name);
        set_name_long(product.name_long);
        set_name_long_2(product.name_long_2);
        setSlug(product.slug);
        setBrand(product.brand);
        setPrice(product.price);
        setCategory(product.category);
        setCountInStock(product.countInStock);
        setDescription(product.description);
      }
    }

  }, [product, successUpdate, dispatch, productId]);

  const submit = (e) => {
    e.preventDefault();

    dispatch(updateProduct({
      _id: productId,
      name,
      name_long,
      name_long_2,
      slug,
      brand,
      price,
      category,
      countInStock,
      description,
      image
    }));
    setHideUpdated(false);
    scrollToTop();
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append('image', file);
    formData.append('product_id', productId);

    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/products/upload-image/`,
        formData,
        config
      );

      setImage(data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <>
      <Meta title="Edit Product | Echidna Admin" />

      <div
        className="inline-flex items-center text-sm cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <HiChevronLeft className="mr-1" />
        Back
      </div>

      {loading ?
        <div className="flex justify-center pt-14">
          <LoadingDots />
        </div>
        : error ? <h2 className="text-xl text-center mt-40">{error}</h2>
          : <>

            <h2 className="text-xl mb-4 font-bold border-b-2 pb-2 mt-8">Echidna Product</h2>

            <div className={`flex items-center mb-8 max-w-lg justify-center border shadow-sm rounded-md mx-auto relative ${hideUpdated ? 'hidden' : ''}`}>
              <p
                className={`text-xs text-center p-2 rounded font-bold text-gray-700`}
                onClick={() => setHideUpdated(true)}
              >
                Your changes have been saved
              </p>
              <HiOutlineX
                className="cursor-pointer absolute right-2 text-gray-400"
                onClick={() => setHideUpdated(true)}
              />
            </div>

            {errorUpdate &&
              <div className={`flex items-center mb-8 max-w-lg justify-center border shadow-sm rounded-md mx-auto relative ${hideUpdatedError ? 'hidden' : ''}`}>
                <p
                  className={`text-xs text-center p-2 rounded font-bold text-gray-700`}
                  onClick={() => setHideUpdatedError(true)}
                >
                  {errorUpdate}
                </p>
                <HiOutlineX
                  className="cursor-pointer absolute right-2 text-gray-400"
                  onClick={() => setHideUpdatedError(true)}
                />
              </div>}

            <div className="md:grid md:grid-cols-3 md:gap-6 mt-10 sm:mt-0">

              <div className="md:col-span-1">
                <h3 className="">Echidna Product Details</h3>
                <p className="mt-1 text-sm text-gray-600">Edit Echidna product details.</p>
              </div>

              <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={submit} className="border rounded-md">

                  <div className="px-4 py-5 sm:p-6">

                    <div className="grid grid-cols-6 gap-6">

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          value={name ?? ''}
                          onChange={(e) => setName(e.target.value)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                          onClick={() => setHideUpdated(true)}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="slug" className="block text-sm font-medium text-gray-700">
                          Slug
                        </label>
                        <input
                          type="text"
                          id="slug"
                          value={slug ?? ''}
                          onChange={(e) => setSlug(e.target.value)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                          onClick={() => setHideUpdated(true)}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="brand" className="block text-sm font-medium text-gray-700">
                          Brand
                        </label>
                        <input
                          type="text"
                          id="brand"
                          value={brand ?? ''}
                          onChange={(e) => setBrand(e.target.value)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                          onClick={() => setHideUpdated(true)}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                          Price
                        </label>
                        <input
                          type="number"
                          id="price"
                          value={price ?? 0}
                          onChange={(e) => setPrice(e.target.value)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                          onClick={() => setHideUpdated(true)}
                        />
                      </div>

                      <div className="col-span-6 ">
                        <label htmlFor="detailed-name" className="block text-sm font-medium text-gray-700">
                          Detailed name
                        </label>
                        <input
                          type="text"
                          id="detailed-name"
                          value={name_long ?? ''}
                          onChange={(e) => set_name_long(e.target.value)}
                          onClick={() => setHideUpdated(true)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-6 ">
                        <label htmlFor="detailed-name-2" className="block text-sm font-medium text-gray-700">
                          Detailed name 2
                        </label>
                        <input
                          type="text"
                          id="detailed-name-2"
                          value={name_long_2 ?? ''}
                          onChange={(e) => set_name_long_2(e.target.value)}
                          onClick={() => setHideUpdated(true)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-6 ">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                          Description
                        </label>
                        <textarea
                          type="text"
                          id="description"
                          rows="3"
                          value={description ?? ''}
                          onChange={(e) => setDescription(e.target.value)}
                          onClick={() => setHideUpdated(true)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        ></textarea>
                      </div>

                      <div className="col-span-6 ">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                          Category
                        </label>
                        <input
                          type="text"
                          id="category"
                          value={category ?? ''}
                          onChange={(e) => setCategory(e.target.value)}
                          onClick={() => setHideUpdated(true)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-6 ">
                        <label htmlFor="countInStock" className="block text-sm font-medium text-gray-700">
                          Amount in stock
                        </label>
                        <input
                          type="number"
                          id="countInStock"
                          value={countInStock ?? 5}
                          onChange={(e) => setCountInStock(e.target.value)}
                          onClick={() => setHideUpdated(true)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      {/* <div className="col-span-3">
                        <input
                          placeholder="Image"
                          type="text"
                          value={image}
                          onChange={(e) => setImage(e.target.value)}
                          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        />
                      </div> */}

                      <div className="col-span-6">
                        <label className="text-sm border rounded-md px-4 py-2 cursor-pointer">
                          Upload Image
                          <input
                            type="file"
                            onChange={handleImageUpload}
                            hidden
                          />
                        </label>

                      </div>

                    </div>
                  </div>

                  <div className="px-4 py-3 bg-gray-100 rounded-b-md text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md text-gray-700 border-gray-500
           focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* <div className="py-8">
                <div className="border-t border-gray-200" />
              </div>

              <div className="md:grid md:grid-cols-3 md:gap-6">

                <div className="md:col-span-1">
                  <div className="sm:mt-0">
                    <h3 className="">Shipping Information</h3>
                    <p className="mt-1 text-sm text-gray-600">Set your shipping address.</p>
                  </div>
                </div>

                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>

                    <div className="border rounded-md">

                      <div className="px-4 py-5 sm:p-6"> */}

            {/* <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="name_shipping_address" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name_shipping_address"
                        placeholder={shippingAddress ? shippingAddress.name : ''}
                        // value=""
                        // onChange={(e) => setName(e.target.value)}
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                        Street address
                      </label>
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        autoComplete="street-address"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={shippingAddress ? shippingAddress.address : ''}
                      // value={shippingAddress.}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={shippingAddress ? shippingAddress.city : ''}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        State
                      </label>
                      <input
                        type="text"
                        name="region"
                        id="region"
                        autoComplete="address-level1"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={shippingAddress ? shippingAddress.state : ''}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                        ZIP / Postal code
                      </label>
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={shippingAddress ? shippingAddress.postalCode : ''}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 text-sm"
                        placeholder={shippingAddress ? shippingAddress.country : ''}
                      >
                        <option>United States</option>
                        <option>Canada</option>
                        <option>UK</option>
                      </select>
                    </div>

    </div> */}
            {/* </div>

                      <div className="px-4 py-3 bg-gray-100 rounded-b-md text-right sm:px-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md text-gray-700 border-gray-500
                     focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}

            {/* <div className="py-8">
        <div className="border-t border-gray-200" />
      </div> */}

            {/* <div className="md:grid md:grid-cols-3 md:gap-6">

        <div className="md:col-span-1">
          <div className="">
            <h3 className="">Notifications</h3>
            <p className="mt-1 text-sm text-gray-600">Change your notification preferences.</p>
          </div>
        </div>

        <div className="mt-5 md:mt-0 md:col-span-2">
          <form>
            <div className="border sm:rounded-md">
              <div className="px-4 py-5 space-y-6 sm:p-6">

                <fieldset>
                  <legend className="text-base font-medium text-gray-900">By Email</legend>

                  <div className="flex items-start mt-4">
                    <div className="flex items-center h-5">
                      <input
                        id="order-info"
                        type="checkbox"
                        className="focus:ring-0 h-4 w-4 text-cyan-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="order-info" className="font-medium text-gray-700">
                        Order Status
                      </label>
                      <p className="text-gray-500">Get notified with order updates.</p>
                    </div>
                  </div>

                  <div className="flex items-start mt-4">
                    <div className="flex items-center h-5">
                      <input
                        id="newsletters"
                        type="checkbox"
                        className="focus:ring-0 h-4 w-4 text-cyan-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="newsletters" className="font-medium text-gray-700">
                        Newsletters
                      </label>
                      <p className="text-gray-500">Get notified with latest products and promotions.</p>
                    </div>
                  </div>

                </fieldset>

              </div>

              <div className="px-4 py-3 bg-gray-100 text-right sm:px-6 rounded-b-md">
                <button
                  type="submit"
                  className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md 
                    text-gray-700 border-gray-500
                     focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
    </div> */}
          </>}
    </>
  );
}

export default AdminEditProduct;
