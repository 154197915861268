import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CheckoutSteps from '../components/CheckoutSteps';
import { createOrder } from '../redux/actions/orderActions';
import { ORDER_CREATE_RESET } from '../redux/constants/orderConstants';
import Meta from '../components/Meta';
import { productDetailsReset } from '../redux/actions/productActions';

function CreateOrderPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const params = useParams();
  // const { id: orderId } = params;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const orderCreate = useSelector(state => state.orderCreate);
  const { order, error, success } = orderCreate;

  const cart = useSelector(state => state.cart);

  const productDetails = useSelector(state => state.productDetails);
  const { product } = productDetails;

  useEffect(() => {
    if (product.name) {
      dispatch(productDetailsReset());
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (cart.cartItems.length === 0 || !userInfo) {
      navigate('/');
    }
    if (!cart.paymentMethod) {
      navigate('/payment');
    }
    if (success) {
      navigate(`/submit-order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [cart, success, dispatch, order, navigate, userInfo]);

  // Add new objects in cart redux state
  cart.itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2);
  cart.shippingPrice = (cart.itemsPrice > 100 ? 0 : 10).toFixed(2);
  cart.taxPrice = Number((0.082) * cart.itemsPrice).toFixed(2);
  cart.totalPrice = (Number(cart.itemsPrice) + Number(cart.shippingPrice) + Number(cart.taxPrice)).toFixed(2);

  const submitOrder = () => {
    dispatch(createOrder({
      orderItems: cart.cartItems,
      shippingAddress: cart.shippingAddress,
      paymentMethod: cart.paymentMethod,
      itemsPrice: cart.itemsPrice,
      shippingPrice: cart.shippingPrice,
      taxPrice: cart.taxPrice,
      totalPrice: cart.totalPrice
    }));
  };

  return (
    <>
      <Meta title="Review Order | Echidna" />

      <CheckoutSteps step1 step2 step3 step4 />

      <h2 className="text-xl font-bold border-b-2 pb-2 mb-6">Review Order</h2>
     
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8">
        <div className="lg:col-span-2">

          <div className="grid md:grid-cols-2 text-sm">

            <div>
              <p className="">Shipping Address</p>
              {/* Fix shipping address name redux state */}
              <p>
                {cart.shippingAddress.nameOnShippingAddress}
              </p>

              <p>{cart.shippingAddress.address}</p>
              <p>{cart.shippingAddress.city} {cart.shippingAddress.state},  {cart.shippingAddress.postalCode}</p>
              <p>{cart.shippingAddress.country}</p>
            </div>

            <div>
              <h2 className="mt-4 md:mt-0">Payment Method</h2>
              <p>{cart.paymentMethod}</p>
            </div>

          </div>

          <div className="mt-14 text-sm">
            <h2 className="pb-3 border-b font-bold">Order Items</h2>
            {cart.cartItems.length === 0
              ? 'Your bag is empty'
              : cart.cartItems.map((item, index) => (
                <div key={index} className="flex justify-between items-center mt-4">
                  <div className="flex items-center">
                    <Link to={`/product/${item.slug}`}>
                      <img
                        src={item.image}
                        alt=""
                        className="w-32 mr-10"
                      />
                    </Link>

                    <Link to={`/product/${item.slug}`}>{item.brand + ' ' + item.name}</Link>
                  </div>

                  <div className="text-right ml-3">
                    <p>Quantity: {item.qty}</p>
                    <p>${item.price}</p>
                  </div>
                </div>
              ))
            }
          </div>

        </div>

        <div className="text-sm text-center space-y-1 mt-5 sm:mt-0">
          <div className="bg-gray-100 border border-gray-300 rounded-md p-4">
            <h3 className="mb-4 text-lg font-bold">Order Summary</h3>
            <p>Subtotal: ${cart.itemsPrice}</p>
            <p>Shipping: {cart.itemsPrice > 100 ? 'Free' : `$${10}`}</p>
            <p>Tax: ${cart.taxPrice}</p>

            <p className="font-bold">Total: ${cart.totalPrice}</p>

            {error &&
              <p className="mt-3">{error}</p>}


            {/* <div className="z-0 relative">
              <PayPalButton
                amount={order.totalPrice}
                onSuccess={successPaymentHandler}
                className="relative"
              />
            </div> */}

            <div className="text-center mt-4">
              <button
                className="mt-4 px-16 py-3 rounded bg-black font-bold text-white hover:text-gray-300 transition duration-200 ease-in-out w-full"
                onClick={submitOrder}
              >Proceed to Payment</button>
            </div>
          </div>

        </div>
      </div>


    </>
  );
}

export default CreateOrderPage;
