import React, { useState } from 'react';
import { HiOutlineSearch } from 'react-icons/hi';

import { useLocation, useNavigate } from 'react-router-dom';

function SearchBox({ mobile, closeSlider }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [query, setQuery] = useState('');

  const submitSearch = (e) => {
    e.preventDefault();

    if (query) {
      navigate(`/search/?q=${query}&page=1`);
    } else {
      navigate(location.pathname);
    }

    setQuery('');
  };

  const keyUpSubmit = (e) => {
    if (mobile) {
      if (e.keyCode === 13) {
        closeSlider = true;
      }
    }
  };

  return (
    <form
      className={`${mobile ? 'flex mt-6 ml-8 w-9/12 h-12' : 'hidden sm:flex w-48 h-[35px]'} bg-gradient-to-r from-stone-300 via-slate-800 to-slate-800 items-center rounded-full mr-3`}
      onSubmit={submitSearch}
    >
      <input
        className={`p-2 h-full w-4 flex-grow flex-shrink rounded-l-full border
        border-stone-300 focus:border-slate-800
      focus:outline-none focus:ring-0 text-sm ${mobile ? 'pl-4' : 'pl-3'}`}
        placeholder="Search"
        type="text"
        name="q"
        value={query}
        onKeyUp={keyUpSubmit}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button
        type="submit"
        className={`${mobile ? 'p-3' : 'p-2'} mr-1 focus:outline-none`}
        onClick={closeSlider}
      >
        <HiOutlineSearch className="text-white" />
      </button>
    </form>
  );
}

export default SearchBox;
