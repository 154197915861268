import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { listProductDetails } from '../redux/actions/productActions';

import Rating from '../components/Rating';
import { HiChevronLeft } from 'react-icons/hi';
import { AiOutlineZoomIn } from "react-icons/ai";
import Meta from '../components/Meta';
import ImageModal from '../components/ImageModal';
import Reviews from '../components/Reviews';

// import ProgressBar from '@badrap/bar-of-progress';
import DetailsTab from '../components/DetailsTabs';
import LoadingDots from '../components/LoadingDots';

// const progress = new ProgressBar({
//   size: 5,
//   color: '#FE595E',
//   className: 'z-50',
//   delay: 100
// });

function ProductDetail() {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const productDetails = useSelector(state => state.productDetails);
  const { error, loading, product } = productDetails;

  // if (loading) {
  //   progress.start()
  // }
  // else if (product) {
  //   progress.finish()
  // }

  // useEffect(() => {

  //   console.log('location changed');
  // }, [location, loading, product, dispatch]);

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector(state => state.productReviewCreate);
  const { success: successProductReview } = productReviewCreate;

  const [qty, setQty] = useState(1);
  const [isImageModal, setIsImageModal] = useState(false);

  useEffect(() => {
    dispatch(listProductDetails(id));
  }, [dispatch, id, successProductReview]);

  // const product = products.find(p => p._id === id);

  const addToCart = () => {
    navigate(`/bag/${id}?qty=${qty}`);
  };

  const decrementQty = () => {
    if (qty > 1)
      setQty(qty - 1);
  };

  const incrementQty = () => {
    if (qty < product.countInStock)
      setQty(qty + 1);
  };

  const images = [product.image];

  return (
    <>
      <Meta title={loading ? 'Echidna' : product.brand + ' ' + product.name + ' | Echidna'} />

      <div
        className="inline-flex items-center text-sm cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <HiChevronLeft className="mr-1" />
        Back
      </div>

      {loading ?
        <div className="flex justify-center pt-14">
          <LoadingDots />
        </div>
        : error ? <h2 className="text-xl text-center mt-40">{error}</h2>
          : <>
            {isImageModal &&
              <ImageModal
                open={isImageModal}
                setOpen={setIsImageModal}
                images={images}
              />
            }

            <div className="grid md:grid-cols-2 md:gap-x-16 mt-8">

              <div>
                <div
                  className="relative"
                  onClick={() => setIsImageModal(true)}
                >
                  <img
                    src={product.image}
                    alt=""
                    className="w-[600px] mb-6 p-6 cursor-zoom-in"
                  />

                  <AiOutlineZoomIn className="w-6 h-6 absolute top-0 right-2 cursor-zoom-in text-neutral-500" />
                </div>

              </div>

              <div>
                <h2 className="text-xl font-bold">
                  {product.brand + ' ' + product.name}
                </h2>
                <p className="font-bold mb-1">${product.price}</p>
                {product.countInStock ?
                  <p className="text-xs mb-2">In stock</p>
                  : <p className="text-xs mb-2">Out of stock</p>}

                {product.numReviews > 0 &&
                <Rating
                  rating={product.rating}
                  numReviews={product.numReviews}
                  className="text-xl"
                /> 
                }
                
                <p className="text-xs mt-10 mb-1">Quantity</p>

                <div className="flex items-center text-gray-500">

                  <button
                    className="px-3 text-[22px] text-gray-500"
                    onClick={incrementQty}
                  >+</button>

                  <p className="text-lg">/</p>

                  <button
                    className="px-3 text-[20px] text-gray-500"
                    onClick={decrementQty}
                  >–</button>

                  <input
                    type="number"
                    min="1"
                    max={product.countInStock <= 5 ? product.countInStock : 5}
                    placeholder={1} className="border-0 rounded-md ring-2 focus:ring-2 focus:ring-gray-300 ring-gray-300 px-2 w-9 mt-1 text-center leading-3 ml-1"
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                  />

                </div>

                <button
                  onClick={addToCart}
                  disabled={product.countInStock === 0}
                  className={`w-full sm:w-auto sm:px-20 py-3
                    text-white bg-black hover:text-gray-300 mt-10 disabled:cursor-not-allowed
                    disabled:opacity-40 
                    disabled:bg-gray-400 disabled:text-white 
                    transition duration-200 ease-in-out uppercase font-bold block text-sm rounded`}
                >
                  Add to bag
                </button>

                <DetailsTab
                  description={product.description}
                />

              </div>
            </div>

            <hr className="mt-14" />

            <Reviews product={product} userInfo={userInfo} />

          </>}
    </>
  );
}

export default ProductDetail;
