import React from 'react';
import { ImStarEmpty, ImStarFull, ImStarHalf } from "react-icons/im";

function Rating({ rating, numReviews, ...props }) {
  return (
    <>
      <span className={`flex gap-x-[2px] ${props.className}`}>
        {rating >= 1 ?
          (<ImStarFull className="text-yellow-500" />)
          : rating >= 0.3 ?
            <ImStarHalf className="text-yellow-500" />
            :
            // Outline
            <ImStarEmpty className="text-yellow-500" />}

        {rating >= 2 ?
          (<ImStarFull className="text-yellow-500" />)
          : rating >= 1.3 ?
            <ImStarHalf className="text-yellow-500" />
            :
            <ImStarEmpty className="text-yellow-500" />}

        {rating >= 3 ?
          (<ImStarFull className="text-yellow-500" />)
          : rating >= 2.3 ?
            <ImStarHalf className="text-yellow-500" />
            :
            <ImStarEmpty className="text-yellow-500" />}

        {rating >= 4 ?
          (<ImStarFull className="text-yellow-500" />)
          : rating >= 3.3 ?
            <ImStarHalf className="text-yellow-500" />
            :
            <ImStarEmpty className="text-yellow-500" />}

        {rating >= 5 ?
          (<ImStarFull className="text-yellow-500" />)
          : rating >= 4.3 ?
            <ImStarHalf className="text-yellow-500" />
            :
            <ImStarEmpty className="text-yellow-500" />}
      </span>

      {numReviews &&
        <p className="text-xs text-gray-400 mt-1">{numReviews} reviews</p>}
    </>
  );
}

export default Rating;
