import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { listUsers, deleteUser } from '../redux/actions/userActions';
import Meta from '../components/Meta';
import Moment from 'react-moment';
import { IoTrashOutline } from 'react-icons/io5';
import LoadingDots from '../components/LoadingDots';

function AdminListUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;
  // console.log(userInfo);

  const userList = useSelector(state => state.userList);
  const { loading, error, users } = userList;

  const userDelete = useSelector(state => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    if (!userInfo) {
      navigate('/404');
    }
    if (userInfo) {
      if (!userInfo.isAdmin)
        navigate('/404');
      else if (userInfo.isAdmin)
        dispatch(listUsers());
    }
  }, [userInfo, navigate, dispatch, successDelete]);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this user?'))
      dispatch(deleteUser(id));
  };

  return (
    <>
      <Meta title="Users | Echidna Admin" />

      <h2 className="font-bold text-xl mb-4 border-b-2 pb-2">Echidna Users</h2>

      {loading ?
        <div className="flex justify-center pt-14">
          <LoadingDots />
        </div>
        : error ? <p className="text-center text-xl">{error}</p>
          : users.length === 0 ? <p>No users to show</p>
            : users.map((user) => (
              <div
                key={user._id}
                className="border rounded-md mb-8 text-sm"
              >
                <div className="grid grid-cols-2">
                  <div className="bg-gray-100 p-5 rounded-md rounded-b-none sm:rounded-tl-md sm:rounded-none">

                    <Link className="text-[13px] font-bold"
                      to={`/portal/admin/user/${user._id}`}
                    >View/Edit User Details</Link>
                  </div>

                  <div className="px-5 sm:p-5 bg-gray-100 sm:rounded-tr-md text-xs flex justify-end">
                    <button onClick={() => handleDelete(user._id)}
                      className="flex items-center"
                    >
                      <IoTrashOutline className="h-5 w-5 text-zinc-400 mr-1" />

                      Delete user</button>
                  </div>
                </div>


                <div className="p-5">

                  <p>ID: {user._id} </p>
                  <p>Name: {user.name}</p>
                  <p>Email: {user.email}</p>

                  <p>Admin: {user.isAdmin ? 'True' : 'False'}</p>

                </div>

              </div>
            ))
      }
    </>
  );
}

export default AdminListUsers;
