import React from 'react';
import { Link } from 'react-router-dom';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { scrollToTop } from '../lib/scrollToTop';

function Paginator({ pages, page, query = '', isAdmin = false }) {
  let q;

  if (query.includes('?q=')) {
    q = query.split('?q=')[1].split('&')[0];
  }

  return (pages > 1 && (
    <div className="mt-10 relative">
      {page !== 1 &&
        <Link
          className="text-sm px-6 py-2 rounded mr-2 absolute left-1/2 -ml-28"

          // to={!isAdmin ? (
          //   q ? `/search/?q=${q}&page=${page - 1}`
          //     : `?page=${page - 1}`
          // ) : (
          //   q ? `/portal/admin/product-list/?q=${q}&page=${page - 1}`
          //     : `/portal/admin/product-list/?page=${page - 1}`
          // )}

          to={q
            ? `/search/?q=${q}&page=${page - 1}`
            : `?page=${page - 1}`
          }

          onClick={scrollToTop}
        >
          <div className="flex items-center">
            <IoChevronBack className="h-[14px] w-[14px] mr-[2px]" />
            Prev
          </div>
        </Link>}

      <span className="text-sm absolute left-1/2 py-2 -ml-3">
        {page} / {pages}
      </span>

      {page < pages &&
        <Link
          className="px-6 py-2 text-sm rounded absolute right-1/2 -mr-28"
          // to={!isAdmin ? (
          //   q ? `/search/?q=${q}&page=${page + 1}`
          //     : `?page=${page - 1}`
          // ) : (
          //   q ? `/portal/admin/product-list/?q=${q}&page=${page - 1}`
          //     : `/portal/admin/product-list/?page=${page + 1}`
          // )}

          to={q
            ? `/search/?q=${q}&page=${page + 1}`
            : `?page=${page + 1}`
          }
          onClick={scrollToTop}
        >
          <div className="flex items-center">

            Next
            <IoChevronForward className="h-[14px] w-[14px] mt-[1px] ml-[1px]" />
          </div>

        </Link>
      }
    </div>
  ));
}

export default Paginator;
