import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CheckoutSteps from '../components/CheckoutSteps';
import { savePaymentMethod } from '../redux/actions/cartActions';

import Meta from '../components/Meta';

function ChoosePaymentPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector(state => state.cart);
  const { shippingAddress } = cart;

  if (!shippingAddress.address) {
    navigate('/shipping');
  }

  useEffect(() => {
    if (cart.cartItems.length === 0 || !userInfo) {
      navigate('/');
    }
  }, [userInfo, navigate, cart]);

  const [paymentMethod, setPaymentMethod] = useState('PayPal');

  const submit = (e) => {
    e.preventDefault();

    dispatch(savePaymentMethod(paymentMethod));
    navigate(`/review-order`);
  };

  return (
    <>
      <Meta title="Select Payment | Echidna" />

      <CheckoutSteps step1 step2 step3 />

      <h2 className="text-xl font-bold border-b-2 pb-2">Payment Type</h2>

      <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">

        <div className="md:col-span-1">
          <p className="text-sm text-gray-600">Select your payment type.</p>
        </div>

        <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={submit} className="border rounded-md">

            <div className="flex items-center justify-between text-sm px-4 py-5 sm:p-6">
              <div className="mr-3 max-w-[140px] sm:max-w-xl">
                Pay with Credit / Debit Visa Card
              </div>

              <button
                type="submit"
                className="inline-flex justify-center py-2 px-5 border border-transparent rounded text-white bg-black hover:text-gray-300
                        transition duration-200 ease-in-out
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 font-bold"
                onClick={() => setPaymentMethod("PayPal")}
              >
                Select Visa
              </button>
            </div>


            {/* <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                        Credit / Debit Card Number
                      </label>
                      <input
                        type="number"
                        id="card-number"
                        required
                        value={name ?? ''}
                        onChange={(e) => setName(e.target.value)}
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                        Street address
                      </label>
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        required
                        autoComplete="street-address"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        required
                        autoComplete="address-level2"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        State
                      </label>
                      <input
                        type="text"
                        name="region"
                        id="region"
                        required
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                        ZIP / Postal code
                      </label>
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        required
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        required
                        autoComplete="country-name"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 text-sm"
                      >
                        <option></option>
                        <option>United States</option>
                        <option>Canada</option>
                        <option>UK</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="my-3 text-right  sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-5 border border-transparent text-sm rounded-md text-gray-700 bg-slate-300 hover:text-black
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                  >
                    Next
                  </button>
                </div> */}

          </form>
        </div>

      </div>

      <div className="md:grid md:grid-cols-3 md:gap-6 mt-10">
        
        <div className="md:col-span-1" />

        <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={submit} className="border rounded-md">
            <div className="px-4 py-5 text-sm sm:p-6">

              <div className="flex items-center justify-between">
                <div className="">
                  Pay with PayPal
                </div>

                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-5 border border-transparent text-sm rounded text-white bg-black hover:text-gray-300 transition duration-200 ease-in-out
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 font-bold"
                  onClick={() => setPaymentMethod('PayPal')}
                >
                  Select PayPal
                </button>
              </div>
            </div>


          </form>
        </div>
      </div>
    </>
  );
}

export default ChoosePaymentPage;
