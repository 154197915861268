import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Rating from './Rating';

import Moment from 'react-moment';

import { Listbox, Transition } from '@headlessui/react';
// import { SelectorIcon } from '@heroicons/react/solid';

import { createProductReview } from '../redux/actions/productActions';
import { PRODUCT_CREATE_REVIEW_RESET } from '../redux/constants/productConstants';

import { ImStarEmpty, ImStarFull } from "react-icons/im";

function Reviews({ product, userInfo }) {
  const dispatch = useDispatch();

  const productReviewCreate = useSelector(state => state.productReviewCreate);
  const { error: errorProductReview, loading: loadingProductReview, success: successProductReview } = productReviewCreate;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (successProductReview) {
      setRating(0);
      setComment('');
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
  }, [dispatch, successProductReview]);

  const submitReview = (e) => {
    e.preventDefault();

    dispatch(createProductReview(product.slug, {
      rating,
      comment
    }));
  };

  return (
    <>
      <div className="grid md:grid-cols-3 md:gap-6 mt-14 max-w-5xl mx-auto">

        <div className="md:col-span-1">
          <h3 className="text-gray-900 mb-4 font-bold">Reviews</h3>
        </div>

        <div className="md:col-span-2">
          <form onSubmit={submitReview} className="border rounded-md">
            {successProductReview ? <p className="text-lg">{successProductReview}</p> :
              <div className="p-5 space-y-4">

                <div>
                  <p className="text-sm mb-2">Choose rating</p>
                  <span className={`flex gap-x-[3px]`}>
                    {rating >= 1 ?

                      <ImStarFull
                        className="text-yellow-500 cursor-pointer text-2xl"
                        onClick={() => setRating(1)}
                      />
                      :
                      // Outline
                      <ImStarEmpty className={`${rating === 0 ? 'text-gray-300' : 'text-yellow-500'} cursor-pointer text-2xl`}
                        onClick={() => setRating(1)}
                      />}

                    {rating >= 2 ?
                      <ImStarFull className="text-yellow-500 cursor-pointer text-2xl" onClick={() => setRating(2)}
                      />
                      :
                      <ImStarEmpty className={`${rating === 0 ? 'text-gray-300' : 'text-yellow-500'} cursor-pointer text-2xl`}
                        onClick={() => setRating(2)}
                      />}

                    {rating >= 3 ?
                      <ImStarFull className="text-yellow-500 cursor-pointer text-2xl" onClick={() => setRating(3)}
                      />
                      :
                      <ImStarEmpty className={`${rating === 0 ? 'text-gray-300' : 'text-yellow-500'} cursor-pointer text-2xl`}
                        onClick={() => setRating(3)}
                      />}

                    {rating >= 4 ?
                      <ImStarFull className="text-yellow-500 cursor-pointer text-2xl" onClick={() => setRating(4)}
                      />
                      :
                      <ImStarEmpty className={`${rating === 0 ? 'text-gray-300' : 'text-yellow-500'} cursor-pointer text-2xl`}
                        onClick={() => setRating(4)}
                      />}

                    {rating >= 5 ?
                      <ImStarFull className="text-yellow-500 cursor-pointer text-2xl" onClick={() => setRating(5)}
                      />
                      :
                      <ImStarEmpty className={`${rating === 0 ? 'text-gray-300' : 'text-yellow-500'} cursor-pointer text-2xl`}
                        onClick={() => setRating(5)}
                      />}

                  </span>
                </div>


                {/* <label for="username" className="block text-sm font-medium text-gray-700">
                      Username
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">

                      <input type="text" id="username" className="focus:ring-cyan-500 focus:border-cyan-500 flex-1 block w-full rounded-md text-sm border-gray-300" />
                    </div> */}


                <div>
                  <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                    Review
                  </label>

                  <div className="mt-1">
                    <textarea
                      id="comment" rows="3" className=" focus:ring-cyan-500 focus:border-cyan-500 mt-1 block w-full text-sm border border-gray-300 rounded-md"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                {/* <div className="flex items-center text-gray-500">

                    <button
                      className="px-3 text-[22px] text-gray-500"
                      onClick={increaseRating}
                    >+</button>

                    <p className="text-lg">/</p>

                    <button
                      className="px-3 text-[20px] text-gray-500"
                      onClick={decreaseRating}
                    >–</button>

                    <input
                      type="number"
                      min="1"
                      max={product.countInStock <= 5 ? product.countInStock : 5}
                      placeholder={1} className="border-0 focus:rounded-md focus:ring-2 focus:ring-gray-300 px-2 w-9 mt-1 text-center leading-3"
                      value={rating}
                      onChange={(e) => setRating(e.target.value)}
                    />

                  </div> */}

                {/* <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Add review photo(s)
                    </label>

                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">

                        <div className="flex text-sm text-gray-600">
                          <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-cyan-600 hover:text-cyan-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500">
                            <span>Upload a file</span>
                            <input id="file-upload" type="file" className="sr-only" />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>

                        <p className="text-xs text-gray-400">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    </div>

                  </div> */}

              </div>}

            <div className="px-5 py-3 bg-gray-100 text-right rounded-b-md">
              <button
                disabled={rating === 0 || !comment}
                type="submit"
                className={`inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 border-gray-500 hover:text-black
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:text-gray-700`}
              >
                {userInfo ? 'Post review' : 'Login to post review'}
              </button>
            </div>

          </form>
        </div>

        {errorProductReview &&
          <>
            <div className="md:col-span-1" />

            <div className="md:col-span-2 mt-12 md:mt-2 text-sm">
              <p>{errorProductReview}</p>
            </div>
          </>
        }

        <div className="md:col-span-1" />

        <div className="md:col-span-2 mt-12 md:mt-0">
          {product.reviews.length === 0 ?
            // <p className="mt-4 border-t pt-10 text-sm">This item has not received any reviews</p>
            <p className="mt-4 border-t pt-10 text-sm">This item has no written reviews</p>
            : loadingProductReview ? <p className="mt-4 border-t pt-10 text-sm">Submitting review...</p>
              :
              product.reviews.map(review => (
                <div key={review._id} className="mt-3 mb-4 border-t px-5 py-8 text-sm">
                  <p className="">{review.name}</p>
                  <p className="text-[11.5px] mb-1">Posted at {' '}
                    <Moment format="H:mm on L">
                      {review.createdAt}
                    </Moment>
                  </p>

                  <Rating rating={review.rating} className="" />
                  <p className="mt-5">{review.comment}</p>
                </div>
              ))
          }

        </div>

      </div>

      {/* <div className="max-w-3xl mx-auto">

        {product.reviews.length === 0 ?
          <p className="mt-4">This item has no reviews</p>
          : loadingProductReview ? <p className="mt-4">Loading...</p>
            :
            product.reviews.map(review => (
              <div key={review._id} className="my-3 border">
                <p>{review.name}</p>
                <Rating rating={review.rating} />
                <p>{review.createdAt.substring(0, 10)}</p>
                <p>{review.comment}</p>
              </div>
            ))
        }
      </div> */}
    </>

  );
}

export default Reviews;
