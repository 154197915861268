import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDetails, updateUserProfile } from '../redux/actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../redux/constants/userConstants';
import Meta from '../components/Meta';
import { productDetailsReset } from '../redux/actions/productActions';

import { HiOutlineX } from 'react-icons/hi';
import { listMyOrders } from '../redux/actions/orderActions';
import { saveShippingAddress } from '../redux/actions/cartActions';

import { scrollToTop } from '../lib/scrollToTop';
import SelectBox from '../components/SelectBox';

function AccountPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const cart = useSelector(state => state.cart);
  const { shippingAddress } = cart;

  const [nameOnShippingAddress, setNameOnShippingAddress] = useState(shippingAddress.nameOnShippingAddress);
  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [state, setState] = useState(shippingAddress.state);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState(shippingAddress.country);

  const [message, setMessage] = useState('');
  const [hideUpdated, setHideUpdated] = useState(true);

  const dispatch = useDispatch();

  const userDetails = useSelector(state => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector(state => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const redirect = searchParams.get('redirect');

  // const productDetails = useSelector(state => state.productDetails);
  // const { product } = productDetails;

  // useEffect(() => {
  //   if (product.name) {
  //     dispatch(productDetailsReset());
  //   }
  // }, [dispatch, product]);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      if (!user || !user.name || success || userInfo._id !== user._id) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails('profile'));
        dispatch(listMyOrders());
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [userInfo, navigate, dispatch, user, success]);

  const submit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    } else {
      dispatch(updateUserProfile({
        id: user._id,
        name: name,
        email: email,
        password: password
      }));

      if (!hideUpdated) {
        setHideUpdated(true);
        setTimeout(() => {
          setHideUpdated(false);
        }, 1000);
      } else {
        setHideUpdated(false);
      }

      setMessage('');
      setPassword('');
      setConfirmPassword('');

      scrollToTop();
    }
  };

  const submitShippingInfo = (e) => {
    e.preventDefault();

    dispatch(saveShippingAddress({
      nameOnShippingAddress,
      address,
      city,
      state,
      postalCode,
      country,
    }));

    if (!hideUpdated) {
      setHideUpdated(true);
      setTimeout(() => {
        setHideUpdated(false);
      }, 1000);
    } else {
      setHideUpdated(false);
    }

    scrollToTop();
  };

  const countries = [
    {
      'id': 'us',
      'value': 'United States',
    },
    {
      'id': 'ca',
      'value': 'Canada',
    },
    {
      'id': 'gb',
      'value': 'Great Britain',
    },
  ];

  return (
    <>
      <Meta title="My Account | Echidna" />

      <h2 className="text-xl mb-4 font-bold border-b-2 pb-2">Account</h2>

      <div className={`flex items-center mb-8 max-w-lg justify-center border shadow-sm rounded-md mx-auto relative ${hideUpdated ? 'hidden' : ''}`}>
        <p
          className={`text-xs text-center p-2 rounded font-bold text-gray-700`}
          onClick={() => setHideUpdated(true)}
        >Your changes have been saved
        </p>
        <HiOutlineX
          className="cursor-pointer absolute right-2 text-gray-400"
          onClick={() => setHideUpdated(true)}
        />
      </div>

      <div className="md:grid md:grid-cols-3 md:gap-6 mt-10 sm:mt-0">

        <div className="md:col-span-1">
          <div className="">
            <h3 className="">Account Details</h3>
            <p className="mt-1 text-sm text-gray-600">Edit your account details.</p>
          </div>
        </div>

        <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={submit} className="border rounded-md">

            <div className="px-4 py-5 sm:p-6">

              <div className="grid grid-cols-6 gap-6">

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="sm:col-span-3 hidden sm:block" />

                {/* <div className="col-span-6 sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
              Last name
            </label>
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
            />
          </div> */}

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <input
                    type="text"
                    id="email-address"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="sm:col-span-3 hidden sm:block" />

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                  />
                </div>

                {message &&
                  <p className="text-xs col-span-full -mt-4 ml-1">{message}</p>}

              </div>
            </div>

            <div className="px-4 py-3 bg-gray-100 rounded-b-md text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md text-gray-700 border-gray-500
           focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="py-8">
        <div className="border-t border-gray-200" />
      </div>

      <div className="md:grid md:grid-cols-3 md:gap-6">

        <div className="md:col-span-1">
          <div className="sm:mt-0">
            <h3 className="">Shipping Information</h3>
            <p className="mt-1 text-sm text-gray-600">Set your shipping address.</p>
          </div>
        </div>

        <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={submitShippingInfo} className="border rounded-md">

            <div className="px-4 py-5 sm:p-6">

              <div className="grid grid-cols-6 gap-6">

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="name_shipping_address" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name_shipping_address"
                    value={nameOnShippingAddress ?? ''}
                    onChange={(e) => setNameOnShippingAddress(e.target.value)}
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                    Street address
                  </label>
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    autoComplete="street-address"
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                    value={address ?? ''}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="address-level2"
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                    value={city ?? ''}
                    onChange={(e) => setCity(e.target.value)} />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                    State
                  </label>
                  <input
                    type="text"
                    name="region"
                    id="region"
                    autoComplete="address-level1"
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                    value={state ?? ''}
                    onChange={(e) => setState(e.target.value)} />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                    ZIP / Postal code
                  </label>
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autoComplete="postal-code"
                    className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                    value={postalCode ?? ''}
                    onChange={(e) => setPostalCode(e.target.value)} />
                </div>
                {/* 
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 text-sm"
                        selected={country}
                        value={country ?? ''}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value="United States">United States</option>
                        <option value="Canada" >Canada</option>
                        <option value="Great Britain">Great Britain</option>
                      </select>
                    </div> */}

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
                    Country
                  </label>

                  <SelectBox
                    id="country"
                    selectBoxValue={country}
                    setSelectBoxValue={setCountry}
                    selectBoxValues={countries}
                    selectButtonText={'Country'}
                  />

                </div>

              </div>
            </div>

            <div className="px-4 py-3 bg-gray-100 rounded-b-md text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md text-gray-700 border-gray-500
                     focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium  text-gray-900">Profile</h3>
              <p className="mt-1 text-sm text-gray-600">
                This information will be displayed publicly so be careful what you share.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="sm:rounded-md sm">
                <div className="px-4 py-5 space-y-6 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Website
                      </label>
                      <div className="mt-1 flex rounded-md">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          http://
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="focus:ring-cyan-500 focus:border-cyan-500 flex-1 block w-full rounded-none rounded-r-md text-sm border-gray-300"
                          placeholder="www.example.com"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                      About
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className= focus:ring-cyan-500 focus:border-cyan-500 mt-1 ck w-full text-sm border border-gray-300 rounded-md"
                        placeholder="you@example.com"
                        defaultValue={''}
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Brief description for your profile. URLs are hyperlinked.
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Photo</label>
                    <div className="mt-1 flex items-center">
                      <span className="inline-block h-12 w-12 rounded-full bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                      <button
                        type="button"
                        className="ml-5 py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                      >
                        Change
                      </button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Cover photo</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md font-medium text-cyan-600 hover:text-cyan-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500"
                          >
                            <span>Upload a file</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}

      <div className="py-8">
        <div className="border-t border-gray-200" />
      </div>

      <div className="md:grid md:grid-cols-3 md:gap-6">

        <div className="md:col-span-1">
          <div className="">
            <h3 className="">Notifications</h3>
            <p className="mt-1 text-sm text-gray-600">Change your notification preferences.</p>
          </div>
        </div>

        <div className="mt-5 md:mt-0 md:col-span-2">
          <form className="border rounded-md">
            <div className="px-4 py-5 space-y-6 sm:p-6">

              <fieldset>
                <legend className="text-base font-medium text-gray-900">By Email</legend>

                <div className="flex items-start mt-4">
                  <div className="flex items-center h-5">
                    <input
                      id="order-info"
                      type="checkbox"
                      className="focus:ring-0 h-4 w-4 text-cyan-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="order-info" className="font-medium text-gray-700">
                      Order Status
                    </label>
                    <p className="text-gray-500">Get notified with order updates.</p>
                  </div>
                </div>

                <div className="flex items-start mt-4">
                  <div className="flex items-center h-5">
                    <input
                      id="newsletters"
                      type="checkbox"
                      className="focus:ring-0 h-4 w-4 text-cyan-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="newsletters" className="font-medium text-gray-700">
                      Newsletters
                    </label>
                    <p className="text-gray-500">Get notified with latest products and promotions.</p>
                  </div>
                </div>

              </fieldset>

              {/* <fieldset>
                    <div>
                      <legend className="text-base font-medium text-gray-900">Text Updates</legend>
                      <p className="text-sm text-gray-500">Get updates to your phone.</p>
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="enable-text-updates"
                          name="enable-text-updates"
                          type="radio"
                          className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
                        />
                        <label htmlFor="enable-text-updates" className="ml-3 block text-sm font-medium text-gray-700">
                          Enable order updates to your phone
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="no-text-updates"
                          name="no-text-updates"
                          type="radio"
                          className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
                        />
                        <label htmlFor="no-text-updates" className="ml-3 block text-sm font-medium text-gray-700">
                          No text updates
                        </label>
                      </div>
                    </div>
                  </fieldset> */}
            </div>

            <div className="px-4 py-3 bg-gray-100 text-right sm:px-6 rounded-b-md">
              <button
                type="submit"
                className="inline-flex justify-center py-1 px-3 border text-sm font-medium rounded-md 
                    text-gray-700 border-gray-500
                     focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AccountPage;
