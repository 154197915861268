import React, { useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import { scrollToTop } from '../lib/scrollToTop';

function Top() {
  const [scrollY, setScrollY] = useState(0);

  if (typeof window === 'object') {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
  }

  return (
    <button
      className={`z-[5] fixed bottom-6 right-4 bg-gray-400/50 rounded-full h-14 w-14 opacity-50 text-gray-900 ${scrollY > 200 ? 'block' : 'hidden'}`}
      onClick={() => scrollToTop()}
    >
      <BsChevronUp className="text-xl mx-auto" />
    </button>
  );
}

export default Top;
