import React from 'react';

function LoadingDots() {
  return (
    <div className="loading-dots">
      <div className="dot mr-4"></div>
      <div className="dot mr-4"></div>
      <div className="dot"></div>
    </div>
  );
}

export default LoadingDots;
