import { Tab } from '@headlessui/react';
import { classNames } from '../lib/classNames';

export default function DetailsTab({ description }) {
  return (
    <div className="w-full py-4 mt-14">

      <Tab.Group>
        <Tab.List className="flex mb-4">

          <Tab
            key="description"
            className={({ selected }) =>
              classNames(
                'w-full py-2.5 text-sm',
                'font-bold tracking-wide border-b text-gray-400',
                selected
                  ? 'border-black !border-b-[3px] !text-black'
                  : ''
              )
            }
          >
            Description
          </Tab>

          <Tab
            key="shipping"
            className={({ selected }) =>
              classNames(
                'w-full py-2.5 text-sm',
                'font-bold tracking-wide border-b text-gray-400',
                selected
                  ? 'border-black !border-b-[3px] !text-black'
                  : ''
              )
            }
          >
            Shipping
          </Tab>

        </Tab.List>

        <Tab.Panels className="-ml-5">

          <Tab.Panel
            key="descriptionPanel"
            className={classNames(
              'rounded-xl py-2',
              'focus:outline-none focus:ring-0'
            )}
          >
            <p className="ml-5 text-sm">{description}</p>
          </Tab.Panel>

          <Tab.Panel
            key="shippingPanel"
            className={classNames(
              'rounded-xl py-2',
              'focus:outline-none focus:ring-0'
            )}
          >
            <ul className="text-sm list-disc">
              <li>$10 flat rate 3 day shipping for orders under $100.</li>
              <li>Free shipping on orders over $100.</li>
              <li>We ship internationally.</li>
            </ul>

          </Tab.Panel>

        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
