import React from 'react';
import Meta from '../components/Meta';

function NotFound() {
  return (
    <>
      <Meta title="🦘 404 Error | Echidna" />

      <p className="text-4xl text-center mt-40">🦘</p>
      <h2 className="md:text-2xl text-center"> 404 Error - The page you are looking for does not exist</h2>
    </>
  );
}

export default NotFound;
