import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderDetails, listMyOrders } from '../redux/actions/orderActions';
import { ORDER_DELIVER_RESET, ORDER_PAY_RESET } from '../redux/constants/orderConstants';

import Meta from '../components/Meta';
import Moment from 'react-moment';
import { productDetailsReset } from '../redux/actions/productActions';
import LoadingDots from '../components/LoadingDots';

function OrderSubmitted() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { id: orderId } = params;

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const productDetails = useSelector(state => state.productDetails);
  const { product } = productDetails;

  useEffect(() => {
    if (product.name) {
      dispatch(productDetailsReset());
    }
  }, [dispatch, product]);

  const orderDetails = useSelector(state => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderPay = useSelector(state => state.orderPay);
  const { success: successPay, loading: loadingPay } = orderPay;

  const orderDeliver = useSelector(state => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  if (!loading && !error) {
    order.itemsPrice = order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2);
  }

  useEffect(() => {
    if (!userInfo) {
      navigate('/');
    }

    if (!order || successPay || order._id !== Number(orderId) || successDeliver) {
      // Flush order state after payment
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });

      dispatch(getOrderDetails(orderId));
      dispatch(listMyOrders());
    }
  }, [orderId, order, successPay, dispatch, userInfo, navigate, successDeliver]);

  return loading ?
    <div className="flex justify-center pt-14">
      <LoadingDots />
    </div>
    : error ? <h2 className="text-xl text-center mt-40">{error}</h2>
      : (
        <>
          <Meta
            title={`${order.isPaid
              ? (!order.isDelivered
                ? `Order #${order._id} Received` : `Order #${order._id} Complete | Echidna`)
              : 'Submit Payment | Echidna'}
              `}
          />

          {(order.isPaid && !order.isDelivered) && <>
            <h2 className="text-2xl mb-8 text-center p-4">Thanks your order was received!</h2>
            <h2 className="mb-4">Here are your order details:</h2>
          </>
          }

          {order.isPaid ?
            (!order.isDelivered
              ?

              <h2 className="mb-6 text-xl font-bold border-b-2 pb-2">
                Order #{order._id}
              </h2>

              :

              <h2 className="mb-6 text-xl font-bold border-b-2 pb-2">
                Order #{order._id} Complete
              </h2>)
            :

            <h2 className="mb-6 text-xl font-bold border-b-2 pb-2">Submit Order</h2>
          }

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8">
            <div className="lg:col-span-2">
              <div className="grid lg:grid-cols-2 gap-x-4 gap-y-4 text-sm">

                <div>
                  <p>Shipping Address</p>
                  <p>
                    {order.user.name}
                  </p>
                  <p>{order.shippingAddress.address}</p>
                  <p>{order.shippingAddress.city} {order.shippingAddress.state},  {order.shippingAddress.postalCode}</p>
                  <p>{order.shippingAddress.country}</p>

                  <h2 className="mt-4">Email</h2>
                  <p>
                    {order.user.email}
                  </p>
                </div>

                <div>
                  <h2>Payment Method</h2>
                  <p>{order.paymentMethod}</p>

                  {/* Move this info to separate orders page instead of this order-confirmed page */}
                  {order.isPaid && (
                    <>
                      <p className="mt-4">Payment Status
                        <p>
                          Paid at {' '}
                          <Moment format="H:mm on L">
                            {order.paidAt}
                          </Moment>
                        </p>
                      </p>
                    </>
                  )}

                </div>

              </div>

              <div className="mt-14 text-sm">
                <h2 className="pb-3 border-b font-bold">Order Items</h2>
                {order.orderItems.length === 0
                  ? 'Your bag is empty'
                  :

                  order.orderItems.map((item, index) => (
                    <div key={index} className="flex justify-between items-center mt-4">

                      <div className="flex items-center">
                        <Link to={`/product/${item.slug}`}>
                          <img
                            src={item.image}
                            alt=""
                            className="w-20 sm:w-32 mr-10"
                          />
                        </Link>

                        <Link to={`/product/${item.slug}`}>{item.brand + ' ' + item.name}</Link>
                      </div>

                      <div className="ml-3 text-right">
                        <p>Quantity: {item.qty}</p>
                        <p>${item.price}</p>
                      </div>
                    </div>
                  ))
                }
              </div>

            </div>

            <div className="text-sm text-center space-y-1 mt-5 sm:mt-0">
              <div className="bg-gray-100 p-4 rounded-md border border-gray-300">
                <h3 className="mb-4 text-lg font-bold">Order Summary</h3>
                <p>Subtotal: ${order.itemsPrice}</p>
                <p>Shipping: {order.itemsPrice > 100 ? 'Free' : `$${10}`}</p>
                <p className="">Tax: ${order.taxPrice}</p>

                <div className="h-6" />

                <p className="font-bold">Total: ${order.totalPrice}</p>

              </div>

            </div>
          </div>

        </>
      );
}

export default OrderSubmitted;
