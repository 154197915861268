import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { addToCart } from '../redux/actions/cartActions';

import { useDispatch, useSelector } from 'react-redux';
import Bag from '../components/Bag';

import { IoBagOutline } from "react-icons/io5";

import Meta from '../components/Meta';
import { productDetailsReset } from '../redux/actions/productActions';


function BagPage() {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const qty = searchParams.get('qty');

  const dispatch = useDispatch();

  const bag = useSelector(state => state.cart);
  const { cartItems } = bag;

  const productDetails = useSelector(state => state.productDetails);
  const { product } = productDetails;

  useEffect(() => {
    if (product.name) {
      dispatch(productDetailsReset());
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (id) {
      dispatch(addToCart(id, Number(qty)));
    }
  }, [dispatch, id, qty]);

  return (
    <>
      <Meta title="Bag | Echidna" />

      <h3 className="border-b-2 pb-2 flex items-center">
        <IoBagOutline className="h-5 w-5 mr-2" />
        Bag
      </h3>
      <Bag cartItems={cartItems} />

    </>
  );
}

export default BagPage;
