import React from 'react';
import { Link } from 'react-router-dom';

function CheckoutSteps({ step1, step2, step3, step4, step5, orderId }) {
  return (
    <div className="flex items-center justify-center gap-x-5 uppercase text-[10px] sm:text-[12px]  mb-10">
      {/* <div>
          {step1 ?
            <Link to="/login">
              Login
            </Link>
            : <button disabled className="disabled:opacity-40 uppercase">
              Login
            </button>}
        </div> */}

      <div className="text-center">
        {step2 ?
          <Link to="/shipping">
            Shipping
          </Link>
          : <button disabled className="disabled:opacity-40 uppercase">
            Shipping
          </button>}
      </div>

      <div className="text-center">
        {step3 ?
          <Link to="/payment">
              Payment Type
          </Link>
          : <button disabled className="disabled:opacity-40 uppercase">
            Payment Type
          </button>}
      </div>

      <div className="text-center">
        {step4 ?
          <Link to="/review-order">
            Review Order
          </Link>
          : <button disabled className="disabled:opacity-40 uppercase">
            Review Order
          </button>}
      </div>

      <div className="text-center">
        {step5 ?
          <Link to={`/submit-order/${orderId}`}>
            Submit Order
          </Link>
          : <button disabled className="disabled:opacity-40 uppercase">
            Submit Order
          </button>}
      </div>
    </div>
  );
}

export default CheckoutSteps;
