import React, { useEffect } from 'react';
import ProductsList from '../components/ProductsList';
// import { products } from '../products';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts, productDetailsReset } from '../redux/actions/productActions';
import { useNavigate, useLocation } from 'react-router-dom';
import Paginator from '../components/Paginator';
import Meta from '../components/Meta';
import { resetAuth } from '../redux/actions/userActions';
import LoadingDots from '../components/LoadingDots';
import Spinner from '../components/Spinner';

function Home() {
	// const [_products, _setProducts] = useState([]);
	const navigate = useNavigate();
	const location = useLocation();

	let query = location.search;

	const dispatch = useDispatch();
	const productList = useSelector(state => state.productList);

	const productDetails = useSelector(state => state.productDetails);
	const { product } = productDetails;

	// console.log(product.name);

	useEffect(() => {
		if (product.name) {
			dispatch(productDetailsReset());
		}
	}, [dispatch, product]);


	// Get products from productReducer
	const {
		error,
		loading,
		products,
		page,
		pages, 
		totalProducts
	} = productList;


	// Move api request logic to actions
	// useEffect(() => {
	// 	const fetchProducts = async () => {
	// 		const { data } = await axios.get('http://127.0.0.1:8000/api/products/');

	// 		setProducts(data);
	// 	};

	// 	fetchProducts();
	// }, []);

	useEffect(() => {

		// if (loading) { progress.start(); }
		// else if (products) progress.finish();

		dispatch(listProducts(query));
	}, [dispatch, query]);

	return (
		<>
			<Meta title="Echidna" />

			{loading ?
				<div className="flex justify-center pt-14">
					{/* <Spinner className="after:h-5 after:w-5 after:border-t-transparent after:border-b-black after:border-x-black" /> */}
					<LoadingDots />
				</div>
				: error ? <h2 className="text-xl text-center mt-40">{error}</h2>
					:
					<>
						<ProductsList
							products={products}
							page={page} totalProducts={totalProducts}
						/>

						<Paginator page={page} pages={pages} query={query} />

					</>
			}
		</>
	);
}

export default Home;
