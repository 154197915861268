import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDetails } from '../redux/actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../redux/constants/userConstants';
import { listMyOrders } from '../redux/actions/orderActions';

import Meta from '../components/Meta';
import Moment from 'react-moment';
import { productDetailsReset } from '../redux/actions/productActions';
import LoadingDots from '../components/LoadingDots';

function UserOrdersPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector(state => state.userDetails);
  const { loading, error, user } = userDetails;

  const orderListMy = useSelector(state => state.orderListMy);
  const { loading: loadingOrderList, error: errorOrderList, orders } = orderListMy;

  // const orderPay = useSelector(state => state.orderPay);
  // const { success: successPay } = orderPay;

  const productDetails = useSelector(state => state.productDetails);
  const { product } = productDetails;

  useEffect(() => {
    if (product.name) {
      dispatch(productDetailsReset());
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      if (!user || !user.name || userInfo._id !== user._id) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails('profile'));
        dispatch(listMyOrders());
      }
    }
  }, [userInfo, navigate, dispatch, user]);

  return (
    <>
      <Meta title="My Orders | Echidna" />

      <h2 className="font-bold text-xl mb-4 border-b-2 pb-2">My Orders</h2>

      {loadingOrderList ?
        <div className="flex justify-center pt-14">
          <LoadingDots />
        </div>
        : errorOrderList ? <p>{errorOrderList}</p>
          :
          <div className="text-sm">
            {orders.length === 0 ? <p>No orders to show</p>
              : orders.map((order) => (
                <div
                  key={order._id}
                  className="border rounded-md mb-8"
                >
                  <div className="grid sm:grid-cols-2">
                    <div className="bg-gray-100 p-5 rounded-md rounded-b-none sm:rounded-tl-md sm:rounded-none sm:border-b">
                      <Link to={`/order/${order._id}`}
                        className="font-bold text-lg hover:underline"
                      >Order #{order._id}</Link>

                      <p className="text-xs">Order date:{' '}
                        <Moment format="H:mm on L">
                          {order.createdAt}
                        </Moment>
                      </p>
                      <p className="text-xs">Total: ${order.totalPrice}</p>
                    </div>

                    <div className="px-5 pb-5 -mt-3 sm:mt-0 sm:p-5 bg-gray-100 sm:rounded-tr-md text-xs flex border-b">
                      <p className="mr-8">Shipping Address</p>
                      <div>

                        <p>{order.shippingAddress.name}</p>
                        <p>{order.shippingAddress.address}</p>
                        <p>{order.shippingAddress.city}, {order.shippingAddress.state}{' '}
                          {order.shippingAddress.postalCode}
                        </p>
                        <p>{order.shippingAddress.country}</p>
                      </div>
                    </div>
                  </div>

                  <h4 className="mt-4 px-5 text-[13px] font-bold">Order Items</h4>
                  <div className="mt-2">
                    {order.orderItems.map(item => (
                      <div
                        key={item._id}
                        className="flex items-start sm:items-center justify-between p-5 pt-0"
                      >

                        <div className="flex items-center">
                          <img src={item.image}
                            className="w-32 hidden sm:block mr-4"
                            alt="" />

                          <p className="text-[13px] sm:self-center">{item.brand + ' ' + item.name}</p>
                        </div>

                        <div className="text-right text-[13px] self-start sm:self-center">
                          <p>Quantity: {item.qty}</p>
                          <p>${item.price}</p>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              ))}
          </div>}

    </>
  );
}

export default UserOrdersPage;
