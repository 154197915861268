import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { HiOutlineX } from 'react-icons/hi';

function ImageModal({ open, setOpen, images }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-auto z-[100]" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">

          <div className="fixed w-screen inset-y-0 left-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-screen">

                {/* X Button Animation */}
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-4 -ml-12 pt-6 pr-2 z-10">

                    <button
                      type="button"
                      className="rounded-md text-gray-600 hover:text-gray-900 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <HiOutlineX className="h-8 w-8" />

                    </button>

                  </div>
                </Transition.Child>

                <div
                  className="h-full flex flex-col py-6 bg-stone-200 overflow-y-scroll"
                  onClick={() => setOpen(false)}
                >

                  <div className="mt-14">
                    {images.map((image, i) => (
                      <div key={i} className="mx-4">
                        <img
                          src={image}
                          alt=""
                          className="mx-auto mb-8 landscape:h-screen"
                        />
                        <img
                          src={image}
                          alt=""
                          className="mx-auto mb-8 landscape:h-screen"
                        />
                      </div>
                    ))}
                  </div>

                </div>
              </div>

            </Transition.Child>

          </div>
        </div>

      </Dialog>
    </Transition.Root>
  );
}

export default ImageModal;
