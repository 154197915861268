import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Meta from '../components/Meta';
import Moment from 'react-moment';
import { IoTrashOutline } from 'react-icons/io5';
import Paginator from '../components/Paginator';
import { listOrders } from '../redux/actions/orderActions';
import LoadingDots from '../components/LoadingDots';

function AdminListOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const orderList = useSelector(state => state.orderList);
  const { loading, error, orders } = orderList;

  let query = location.search;

  useEffect(() => {
    if (!userInfo) {
      navigate('/404');
    }
    if (userInfo) {
      if (!userInfo.isAdmin)
        navigate('/404');
      else if (userInfo.isAdmin)
        dispatch(listOrders());
    }
  }, [userInfo, navigate, dispatch]);

  return (
    <>
      <Meta title="Orders | Echidna Admin" />

      <h2 className="font-bold text-xl mb-4 border-b-2 pb-2">Echidna Orders</h2>

      {loading ?
        <div className="flex justify-center pt-14">
          <LoadingDots />
        </div>
        : error ? <p className="text-center text-xl">{error}</p>
          : orders.length === 0 ? <p>No orders to show</p>
            :
            orders.map((order) => (
              <div
                key={order._id}
                className="border rounded-md mb-8 text-sm"
              >
                <div className="grid grid-cols-2">
                  <div className="bg-gray-100 p-5 rounded-md rounded-b-none sm:rounded-tl-md sm:rounded-none">

                    <Link className="text-[13px] font-bold"
                      to={`/portal/admin/order/${order._id}`}
                    >View/Edit Order Details</Link>
                  </div>

                  <div className="px-5 sm:p-5 bg-gray-100 sm:rounded-tr-md text-xs flex justify-end">
                    {/* <button onClick={() => handleDelete(order._id)}
                          className="flex items-center"
                        >
                          <IoTrashOutline className="h-5 w-5 text-zinc-400 mr-1" />

                          Delete order
                        </button> */}
                  </div>
                </div>


                <div className="p-5">

                  <p>Order ID: {order._id} </p>
                  <p>Name on Order: {order.user && order.user.name}</p>
                  <p>Order Date:{' '}
                    <Moment format="H:mm L">
                      {order.createdAt}
                    </Moment>
                  </p>
                  <p>Paid:{' '} {order.isPaid ?
                    <span>
                      <Moment format="H:mm L">
                        {order.paidAt}
                      </Moment>
                    </span>
                    : <span>Not yet paid</span>
                  }
                  </p>
                  <p>Delivery Status:{' '} {order.isDelivered ?
                    <span>Delivered at{' '}
                      <Moment format="H:mm on L">
                        {order.deliveredAt}
                      </Moment></span>
                    : <span>Not yet delivered</span>}
                  </p>
                  <p>Total: ${order.totalPrice}</p>

                </div>

              </div>
            ))
      }
    </>
  );
}

export default AdminListOrders;
