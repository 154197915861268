import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { listProducts, productDetailsReset } from '../redux/actions/productActions';
import Meta from '../components/Meta';
import Paginator from '../components/Paginator';
import ProductsList from '../components/ProductsList';
import LoadingDots from '../components/LoadingDots';

function SearchPage() {
	const location = useLocation();
	const dispatch = useDispatch();

	let query = location.search;

	const prettyQuery = query.split('?q=')[1].split('&')[0];

	const productList = useSelector(state => state.productList);
	const { error, loading, products, page, pages } = productList;

	useEffect(() => {
		dispatch(listProducts(query));
	}, [dispatch, query]);

	const productDetails = useSelector(state => state.productDetails);
	const { product } = productDetails;

	useEffect(() => {
		if (product.name) {
			dispatch(productDetailsReset());
		}
	}, [dispatch, product]);

	return (
		<>
			<Meta title={`Search: ${decodeURI(prettyQuery)} | Echidna`} />

			<h2>
				<span>🔍</span> Search results for: {decodeURI(prettyQuery)}
			</h2>
			
			{loading ?
				<div className="flex justify-center pt-14">
					<LoadingDots />
				</div>
				: error ? <h2 className="text-xl text-center mt-40">{error}</h2>
					: products.length > 0
						?
						<>
							<ProductsList
								products={products} searchResults
							/>
							<Paginator
								query={query}
								pages={pages}
								page={page}
							/>
						</>
						: <h2 className="mt-10 text-sm">No products found</h2>
			}
		</>
	);
}

export default SearchPage;
