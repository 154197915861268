import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { listProducts, deleteProduct, createProduct } from '../redux/actions/productActions';
import { PRODUCT_CREATE_RESET } from '../redux/constants/productConstants';
import Meta from '../components/Meta';
import Moment from 'react-moment';
import { IoTrashOutline } from 'react-icons/io5';
import Paginator from '../components/Paginator';
import LoadingDots from '../components/LoadingDots';

function AdminListProducts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const productList = useSelector(state => state.productList);
  const { loading, error, products, pages, page } = productList;

  const productDelete = useSelector(state => state.productDelete);
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = productDelete;


  const productCreate = useSelector(state => state.productCreate);
  const { loading: loadingCreate, error: errorCreate, success: successCreate, product: createdProduct } = productCreate;

  let query = location.search;

  useEffect(() => {
    if (!userInfo) {
      navigate('/404');
    }
    if (userInfo) {
      dispatch({ type: PRODUCT_CREATE_RESET });
      if (!userInfo.isAdmin)
        navigate('/404');
      else if (userInfo.isAdmin)
        dispatch(listProducts(query));
      if (successCreate) {
        navigate(`/portal/admin/product/${createdProduct._id}`);
      }
    }
  }, [userInfo, navigate, dispatch, successDelete, successCreate, createdProduct, query]);

  const handleCreate = () => {
    dispatch(createProduct());
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      dispatch(deleteProduct(id));
    }
  };

  return (
    <>
      <Meta title="Products | Echidna Admin" />

      <h2 className="font-bold text-xl mb-4 border-b-2 pb-2">Echidna Products</h2>

      {loading ?
        <div className="flex justify-center pt-14">
          <LoadingDots />
        </div>
        : error ? <p className="text-center text-xl">{error}</p>
          : <>
            <button onClick={handleCreate} className="text-sm mb-4 py-2 px-6 bg-sky-700/80 text-gray-200 rounded-full font-bold">+ Add new product</button>

            {errorDelete && <p className="text-xl text-center mb-4">Delete error: {errorDelete}</p>}

            {errorCreate && <p className="text-xl text-center mb-4">Create error: {errorCreate}</p>}

            {
              products.length === 0 ? <p>No products to show</p>
                :
                products.map((product) => (
                  <div
                    key={product._id}
                    className="border rounded-md mb-8 text-sm"
                  >
                    <div className="grid grid-cols-2">
                      <div className="bg-gray-100 p-5 rounded-md rounded-b-none sm:rounded-tl-md sm:rounded-none">

                        <Link className="text-[13px] font-bold"
                          to={`/portal/admin/product/${product._id}`}
                        >View/Edit Product Details</Link>
                      </div>

                      <div className="px-5 sm:p-5 bg-gray-100 sm:rounded-tr-md text-xs flex justify-end">
                        <button onClick={() => handleDelete(product._id)}
                          className="flex items-center"
                        >
                          <IoTrashOutline className="h-5 w-5 text-zinc-400 mr-1" />

                          Delete product</button>
                      </div>
                    </div>


                    <div className="p-5">

                      <p>ID: {product._id} </p>
                      <p>Item name: {product.brand + ' ' + product.name}</p>
                      <p>Brand: {product.brand}</p>
                      <p>Price: {product.price}</p>
                      <p>Category: {product.category}</p>
                      <p>Item slug: {product.slug}</p>


                    </div>

                  </div>
                  ))
            }
            <Paginator page={page} pages={pages} isAdmin={true} />

          </>}
    </>
  );
}

export default AdminListProducts;
