import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Provider } from 'react-redux';
import { store } from './redux/store';

ReactDOM.render(
  <PayPalScriptProvider
    options={{
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
      components: "buttons",
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </PayPalScriptProvider>,
  document.getElementById('root')
);
