import { Dialog, Transition } from '@headlessui/react';
import { HiOutlineX } from 'react-icons/hi';

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../redux/actions/userActions';

import { IoBagOutline, IoLogOutOutline } from "react-icons/io5";
import { BsListUl, BsPerson } from "react-icons/bs";

import SearchBox from './SearchBox';

function Slider({ open, setOpen, handleCloseSlider }) {
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const handleLogout = () => {
    dispatch(logout());
    handleCloseSlider();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-auto z-[100]" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed w-[352px] inset-y-0 left-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                {/* X Button Animation */}
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >

                  <div className="absolute top-0 right-5 -ml-12 pt-5 pr-2 z-10">
                    <button
                      type="button"
                      className="rounded-md text-gray-600 hover:text-gray-900 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <HiOutlineX className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>

                </Transition.Child>

                <div className="h-full flex flex-col py-6 bg-stone-200 shadow-xl overflow-y-scroll">
                  <div className="ml-8">
                    <Dialog.Title className="text-xl font-bold text-gray-900">
                      <Link to="/"
                        onClick={handleCloseSlider}
                      >
                        Echidna
                      </Link>
                    </Dialog.Title>
                  </div>

                  <div className="mt-10 relative flex-1">
                    <div className="text-sm">
                      <Link className="px-8 py-3 block hover:bg-stone-300 text-lg" to="/"
                        onClick={handleCloseSlider}
                      >Products</Link>
                      {userInfo ?
                        <Link className="px-8 py-3 block hover:bg-stone-300 text-lg" to="/account"
                          onClick={handleCloseSlider}
                        >Account</Link>
                        : <Link className="px-8 py-3 block hover:bg-stone-300 text-lg" to="/login"
                          onClick={handleCloseSlider}
                        >Login</Link>
                      }
                      {userInfo && <>
                        <Link className="px-8 py-3 block hover:bg-stone-300 text-lg" to="/account/my-orders"
                          onClick={handleCloseSlider}
                        >My Orders</Link>
                        <button
                          className="px-8 py-3 w-full text-left hover:bg-stone-300 text-lg flex items-center"
                          onClick={handleLogout}
                        >
                          <IoLogOutOutline className="h-6 w-6 mr-2" />
                          Logout
                        </button>
                      </>
                      }
                    </div>

                    <SearchBox
                      mobile
                      closeSlider={() => handleCloseSlider()}
                    />

                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Slider;
