import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { BsListUl, BsPerson } from 'react-icons/bs';
import { HiChevronDown } from 'react-icons/hi';
import { IoLogOutOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { classNames } from '../lib/classNames';

function UserNavDropdown({ userInfo, handleLogout }) {
  return (
    <Menu as="div" className="relative  text-left">
      <div className="mr-2">
        <Menu.Button className="inline-flex justify-center items-center w-full rounded-md border border-gray-400 shadow-sm px-2 py-1 text-sm font-medium text-gray-700  active:ring-1
                    active:ring-cyan-500">
          Hi, {userInfo.name.split(" ")[0]}!
          <HiChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-6 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">

          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <BsPerson className="mr-2 h-5 w-5" />
                  Account
                </Link>
              )}
            </Menu.Item>
          </div>

          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account/my-orders"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <BsListUl className="h-5 w-5 mr-2" />
                  My Orders
                </Link>
              )}
            </Menu.Item>
          </div>

          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-2 text-sm w-full text-left flex items-center'
                  )}
                  onClick={handleLogout}
                >
                  <IoLogOutOutline className="h-5 w-5 mr-2" />
                  Logout
                </button>
              )}
            </Menu.Item>
          </div>

        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default UserNavDropdown;