import React from 'react';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import TopProductCarousel from './TopProductCarousel';

function ProductsList({ products, searchResults, page }) {
  return (
    <>
      {(!searchResults && page === 1) &&
        <TopProductCarousel />}

      <div className={`grid grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-10 ${searchResults ? 'mt-3' : 'mt-14'}`}>

        {products.map(product => (
          <div key={product._id}>

            <Link to={`/product/${product.slug}`}>
              <img
                src={product.image}
                alt=""
                className="sm:p-10"
              />
            </Link>

            <Link to={`/product/${product.slug}`}>
              <p className="mt-1 sm:-mt-2 text-sm">
                {product.brand}{' '}
                {product.name}
              </p>
            </Link>

            <p className="text-xs font-bold mt-1">
              ${product.price}
            </p>

            {product.numReviews > 0 &&
              <div className="text-gray-400 text-sm mt-3 mb-10">
                <Rating
                  rating={product.rating} numReviews={product.numReviews}
                />
              </div>
            }
          </div>
        ))}
      </div>
    </>
  );
}

export default ProductsList;
