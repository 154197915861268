import React from 'react';

function Footer() {
  return (
    <footer className="bg-stone-200">
      <div className="max-w-screen-xl mx-auto py-14 mt-40 bg-stone-200">
        <div className="mx-6">
          <div className="text-sm text-center">
            Echidna
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
