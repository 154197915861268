import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BagItem from './BagItem';

function Bag({ cartItems }) {
  const navigate = useNavigate();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const submit = (e) => {
    e.preventDefault();

    if (userInfo)
      navigate(`/shipping`);
    else
      navigate(`/login?redirect=shipping`);
  };

  return (
    <div className="mt-10 relative">
      {cartItems.length === 0 ?
        <p className="text-sm">Bag is empty</p>
        : cartItems.map(item => (

          <BagItem
            key={item.product}
            _id={item.product}
            slug={item.slug}
            image={item.image}
            brand={item.brand}
            name={item.name}
            price={item.price}
            _qty={item.qty}
            countInStock={item.countInStock}
          />

          // <div key={item.product} className="flex items-center justify-between mb-8 gap-x-4">

          //   <div className="flex items-center">
          //     <Link to={`/product/${item.slug}`}>
          //       <img
          //         src={item.image}
          //         alt=""
          //         className="w-16 sm:w-32"
          //       />
          //     </Link>
          //     <div className="ml-4 md:ml-12 text-sm">
          //       <Link to={`/product/${item.slug}`}>
          //         <p>{item.brand + ' ' + item.name}</p>
          //       </Link>
          //       <p className="font-bold">${(item.price * item.qty).toFixed(2)}</p>
          //     </div>
          //   </div>

          //   <div className="flex">

          //     <input
          //       type="number"
          //       placeholder={1} className="border p-2 w-16 mr-2 rounded-md block text-sm"
          //       value={item.qty}
          //       min="1"
          //       max={item.countInStock}
          //       onChange={(e) => dispatch(addToCart(item.slug, Number(e.target.value)))}
          //     />

          //     <button
          //       className="p-2"
          //       onClick={() => handleRemoveFromCart(item.product)}
          //     >
          //       <IoTrashOutline className="h-5 w-5 text-zinc-400" />
          //     </button>
          //   </div>
          // </div>
        ))
      }
      <hr className="mt-14" />

      <div className="mt-8 text-right text-sm">
        Subtotal ({cartItems.reduce((acc, item) => acc + parseInt(item.qty), 0)} items):

        ${cartItems.reduce((acc, item) => acc + parseFloat(item.price * item.qty), 0).toFixed(2)}
      </div>

      <button
        disabled={cartItems.length === 0}
        className="px-5 py-2 mt-12 bg-black rounded text-white disabled:bg-gray-400 disabled:text-white disabled:opacity-40 absolute right-0 hover:text-gray-300 transition duration-200 ease-in-out disabled:cursor-not-allowed text-sm font-bold"
        onClick={submit}
      >
        Checkout
      </button>
    </div>
  );
}

export default Bag;
