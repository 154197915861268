import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { listMyOrders } from '../redux/actions/orderActions';
import Spinner from '../components/Spinner';

import { login } from '../redux/actions/userActions';
import Meta from '../components/Meta';

function UserLoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  const userLogin = useSelector(state => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate('/');
      dispatch(listMyOrders());
    }
  }, [userInfo, redirect, navigate, error, dispatch]);

  const submit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));

    setPassword('');
  };

  return (
    <>
      <Meta title="Login | Echidna" />

      <div className="min-h-full flex items-center justify-center py-12">
        <div className="max-w-md w-full space-y-8 border rounded-lg">
          <div className="bg-gray-100 border-b rounded-t-lg pb-3">
            <h2 className="pt-4 text-center text-2xl text-gray-900">Login</h2>
            <p className="mt-1 text-center text-xs text-gray-600 px-2">
              First time user?{' '}
              <Link to={redirect ? `/register?redirect=${redirect}` : '/register'} className="text-indigo-600 hover:text-indigo-500">
                Click here to sign up.
              </Link>
            </p>
          </div>

          <form className="space-y-6 px-3 md:px-6 pb-6" onSubmit={submit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 text-sm"
                placeholder="Email address"
              />

              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                name="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 text-sm"
                placeholder="Password"
              />
            </div>

            {/* <div className="flex items-center justify-between"> */}

            {/* <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-400 focus:ring-0 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 select-none">
                  Remember me
                </label>
              </div> */}

            {/* <div className="text-sm">
                <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div> */}
            {/* </div> */}

            <p className={`text-xs h-4`}>{error ? error : ''}</p>

            <button
              type="submit"
              disabled={loading}
              className={`w-full flex justify-center py-2.5 px-4 bg-black text-white text-sm font-bold rounded-md
                transition duration-200 ease-in-out
                disabled:cursor-not-allowed
                disabled:bg-gray-400 disabled:opacity-40 select-none`}
            >
              {loading ?
                <div className="flex justify-center">
                  <Spinner className="after:h-5 after:w-5 after:border-t-transparent after:border-b-black after:border-x-black" />
                  <p className="ml-3">Signing in...</p>
                </div>
                : 'Sign in'}
            </button>

          </form>
        </div>
      </div>

    </>
  );
}

export default UserLoginPage;

