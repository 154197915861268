import React from 'react';
// import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './lib/scrollToTop';
import Home from './pages/Home';
import BagPage from './pages/BagPage';
import ProductDetail from './pages/ProductDetail';
import UserLoginPage from './pages/UserLoginPage';
import RegisterPage from './pages/RegisterPage';
import AccountPage from './pages/AccountPage';
import ShippingPage from './pages/ShippingPage';
import ChoosePaymentPage from './pages/ChoosePaymentPage';
import CreateOrderPage from './pages/CreateOrderPage';
import OrderPay from './pages/OrderPay';
import UserOrdersPage from './pages/UserOrdersPage';
import SearchPage from './pages/SearchPage';
import NotFound from './pages/NotFound';
import Top from './components/Top';
import OrderDetails from './pages/OrderDetails';
import OrderSubmitted from './pages/OrderSubmitted';
import AdminListUsers from './pages/AdminListUsers';
import AdminEditUser from './pages/AdminEditUser';
import AdminListProducts from './pages/AdminListProducts';
import AdminEditProduct from './pages/AdminEditProduct';
import AdminListOrders from './pages/AdminListOrders';
import AdminEditOrder from './pages/AdminEditOrder';

function App() {

  return (
    <Router>
      <ScrollToTop />

      <Header />

      <main className="max-w-screen-xl mx-auto mt-28 min-h-screen">
        <div className="mx-4 sm:mx-6">
          <Routes>

            <Route path="*" element={<NotFound />} />

            <Route path="/" exact element={<Home />} />

            <Route path="/login" element={<UserLoginPage />} exact />
            <Route path="/register" element={<RegisterPage />} exact />
            <Route path="/account" element={<AccountPage />} exact />
            <Route path="/account/my-orders" element={<UserOrdersPage />} />
            <Route path="/order/:id" element={<OrderDetails />} />

            <Route path='/product/:id' element={<ProductDetail />} />

            <Route path='/search/' element={<SearchPage />} />

            <Route path="/bag/" element={<BagPage />} />
            <Route path="/bag/:id" element={<BagPage />} />


            <Route path="/shipping" exact element={<ShippingPage />} />
            <Route path="/payment" exact element={<ChoosePaymentPage />} />
            <Route path="/review-order" element={<CreateOrderPage />} />
            <Route path="/submit-order/:id" element={<OrderPay />} />
            <Route path="/order-submitted/:id" element={<OrderSubmitted />} />

            {/* Admin */}
            <Route path="/portal/admin/user-list" exact element={<AdminListUsers />} />
            <Route path="/portal/admin/user/:id" element={<AdminEditUser />} />
            <Route path="/portal/admin/product-list" exact element={<AdminListProducts />} />
            <Route path="/portal/admin/product/:id" element={<AdminEditProduct />} />
            <Route path="/portal/admin/order-list" exact element={<AdminListOrders />} />
            <Route path="/portal/admin/order/:id" element={<AdminEditOrder />} />


          </Routes>
          <Top />
        </div>
      </main>

      <Footer />
    </Router>
  );
}

export default App;
