import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveShippingAddress } from '../redux/actions/cartActions';
import { useNavigate } from 'react-router-dom';
import CheckoutSteps from '../components/CheckoutSteps';
import Meta from '../components/Meta';
import { orderDetailsReset } from '../redux/actions/orderActions';
import { classNames } from '../lib/classNames';
import { Listbox, Transition } from '@headlessui/react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import SelectBox from '../components/SelectBox';

function ShippingPage() {
  // Initialise saved address if any
  const cart = useSelector(state => state.cart);
  const { shippingAddress } = cart;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const orderDetails = useSelector(state => state.orderDetails);
  const { order: previousOrder } = orderDetails;

  // console.log(previousOrder);

  useEffect(() => {
    if (cart.cartItems.length === 0 || !userInfo) {
      navigate('/');
    }
    // if (previousOrder) {
    //   dispatch(orderDetailsReset());
    // }
  }, [userInfo, navigate, cart, dispatch]);

  const [nameOnShippingAddress, setNameOnShippingAddress] = useState(shippingAddress.nameOnShippingAddress);
  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [state, setState] = useState(shippingAddress.state);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState(shippingAddress.country);

  const submit = (e) => {
    e.preventDefault();

    dispatch(saveShippingAddress({
      nameOnShippingAddress,
      address,
      city,
      postalCode,
      country,
      state,
    }));

    navigate('/payment');
  };

  const countries = [
    {
      'id': 'us',
      'value': 'United States',
    },
    {
      'id': 'ca',
      'value': 'Canada',
    },
    {
      'id': 'gb',
      'value': 'Great Britain',
    },
  ];


  return (
    <>
      <Meta title="Enter Shipping Information | Echidna" />

      <CheckoutSteps step1 step2 />

      <h2 className="text-xl font-bold border-b-2 pb-2">Shipping</h2>

      <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
        <div className="md:col-span-1">
          <div className="">
            <p className="text-sm text-gray-600">Please provide your shipping address.</p>
          </div>
        </div>

        <form
          onSubmit={submit}
          className="mt-5 md:mt-0 md:col-span-2"
        >
          <div className="border rounded-md px-4 py-5 sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  required
                  value={nameOnShippingAddress ?? ''}
                  onChange={(e) => setNameOnShippingAddress(e.target.value)}
                  className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                />
              </div>

              {/* 
                    <div className="col-span-6 sm:col-span-4">
                      <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <input
                        type="text"
                        id="email-address"
                        autoComplete="email"
                        required
                        value={email ?? ''}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

              <div className="col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  Street address
                </label>
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  required
                  value={address ?? ''}
                  onChange={(e) => setAddress(e.target.value)}
                  autoComplete="street-address"
                  className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  required
                  value={city ?? ''}
                  onChange={(e) => setCity(e.target.value)}
                  autoComplete="address-level2"
                  className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                  State
                </label>
                <input
                  type="text"
                  name="region"
                  id="region"
                  required
                  value={state ?? ''}
                  onChange={(e) => setState(e.target.value)}
                  className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                  ZIP / Postal code
                </label>
                <input
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  autoComplete="postal-code"
                  required
                  value={postalCode ?? ''}
                  onChange={(e) => setPostalCode(e.target.value)}
                  className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  Country
                </label>

                <SelectBox
                  selectBoxValue={country}
                  setSelectBoxValue={setCountry}
                  selectBoxValues={countries}
                  selectButtonText={'Country'}
                />

              </div>
            </div>

          </div>

          <div className="my-3 text-right">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-5 border border-transparent text-sm font-bold rounded-md text-white bg-black hover:text-gray-300
                    transition duration-200 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            >
              Next
            </button>
          </div>

        </form>
      </div>

    </>
  );
}

export default ShippingPage;
