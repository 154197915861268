import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { listTopProducts } from '../redux/actions/productActions';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

function TopProductCarousel() {
  const dispatch = useDispatch();

  const productTopRated = useSelector(state => state.productTopRated);
  const { error, loading, products } = productTopRated;

  const slider = React.useRef(null);

  useEffect(() => {
    dispatch(listTopProducts());
  }, [dispatch]);

  const settings = {
    dots: true,
    dotsClass: "slick-dots carousel-dots",
    infinite: true,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    speed: 700,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true,
    arrows: false
  };

  return (
    <div className="mt-4 relative">
      <h3 className="font-bold uppercase text-center mb-4 text-xl">Top Products</h3>

      <Slider
        ref={slider}
        {...settings}
      >
        {products.map(product => (
          <div
            key={product._id}
          >
            <Link to={`/product/${product.slug}`} >
              <img
                src={product.image} alt=""
                className="max-h-80 mx-auto mb-5"
              />
            </Link>
          </div>
        ))}
      </Slider>

      <div className="absolute bottom-1 right-0 z-10">
        <button
          className={`h-7 w-7 text-gray-400 border bg-gray-300 opacity-60 rounded-full active:border-0 active:ring-2 px-1 sm:active:ring-cyan-500 mr-3`}
          onClick={() => slider?.current?.slickPrev()}>
          <HiOutlineChevronLeft className="h-5 w-5 -ml-[1px]" />
        </button>
        <button
          className={`h-7 w-7 text-gray-400 border bg-gray-300 opacity-60 rounded-full active:border-0 active:ring-2 px-1 sm:active:ring-cyan-500`}
          onClick={() => slider?.current?.slickNext()}>
          <HiOutlineChevronRight
            className="h-5 w-5 -mr-[1px]"
          />
        </button>
      </div>

    </div>
  );
}

export default TopProductCarousel;
